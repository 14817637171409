import { Modal, ModalTitle } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { ModalBody, ModalFooter, ModalHeader } from 'react-bootstrap';
import { requestDemo } from '../services/forms.service';
import _ from "lodash"


const BookADemo = ({ show, onClose }) => {
    const intialState = { firstName: "", lastName: "", email: "", comment: "", acceptTerms: false, phoneNumber: "", };
    const Regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const [formValues, setFormValues] = useState(intialState)
    const [formErrors, setFormErrors] = useState({})
    const [response, setResponse] = useState("");
    const [submitForm, setSubmitForm] = useState(false)

    const handleChange = (e) => {
        const { name, value } = e.target
        setFormValues({ ...formValues, [name]: value })
        console.log(formValues)
    }

    const bookAdemo = async () => {
        setSubmitForm(false)
        let response = await requestDemo(formValues)
        if (response) {
            setFormValues(intialState)
            console.log(response)
            setResponse("success")
            setTimeout(() => {
                setResponse("")
                onClose(false)
            }, 3000)
        } else {
            setResponse("failed")
            setTimeout(() => {
                setResponse("")
            }, 3000)
        }
    }

    useEffect(() => {
        if (submitForm) {
            bookAdemo();
        }
    }, [submitForm])

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(validate(formValues), formValues)
        let errors = validate(formValues)
        if (_.isEmpty(errors)) {
            setFormErrors(errors)
            setSubmitForm(true)
        }else{
            setFormErrors(errors)
            setSubmitForm(false)
        }
    }
    const validate = (values) => {
        let errors = {}
        if (!values.firstName) {
            errors.firstName = true;
        }
        if (!values.lastName) {
            errors.lastName = true;
        }
        if (!values.email || !Regex.test(values.email)) {
            errors.email = true;
        }
        if (!values.comment) {
            errors.comment = true;
        }
        if (!values.phoneNumber) {
            errors.phoneNumber = true;
        }
        if (!values.acceptTerms) {
            errors.acceptTerms = true;
        }
        return errors;
    }

    const validateField = (e) => {
        const { name, value } = e.target;
        if (!value) {
            setFormErrors({ ...formErrors, [name]: true })

        } else {
            if (name === "email") {
                if (!Regex.test(value)) {
                    setFormErrors({ ...formErrors, [name]: true })
                } else {
                    setFormErrors({ ...formErrors, [name]: false })
                }
            } else {
                setFormErrors({ ...formErrors, [name]: false })
            }
        }


    }

    const handleClose = () => {
        onClose(false);
        setFormErrors({})
        setFormValues(intialState)
    };


    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                aria-labelledby="contained-modal-title-vcenter"
                centered

            >
                <ModalHeader
                    closeButton style={{ paddingTop: "30px" }}>
                    <ModalTitle><h2
                        style={{
                            fontSize: "26px",
                            fontWeight: "bolder",
                            margin: 0,
                            padding: "0px 10px"
                        }}
                        className="mg-bottom-10px">Book a Demo</h2></ModalTitle>
                </ModalHeader>
                <ModalBody>
                    {
                        (response === "success") &&
                        <div className="success-message ">
                            <div className="line-rounded-icon success-message-check"></div>
                            <div>Thank you! for you Interest we will contact you soon for Demo</div>
                        </div>
                    }
                    {
                        (response === "failed") &&
                        <div className="error-message " style={{ marginBottom: "50px" }}>
                            <div>Something went Wrong</div>
                        </div>

                    }
                    {
                        (Object.keys(formErrors).length === 1 && formErrors.acceptTerms) &&
                        <div className="error-message " style={{ marginBottom: "50px" }}>
                            <div>Please check Terms and Conditions</div>
                        </div>

                    }
                    <form id="wf-form-Contact-Page-Form" name="wf-form-Contact-Page-Form" data-name="Contact Page Form" onSubmit={handleSubmit}>
                        <div className={`w-layout-grid grid-2-columns form `}>
                            <div ><input type="text" className={formErrors?.firstName ? "fieldInvalid input contact-form w-input" : "input contact-form w-input"} maxLength="256" name="firstName" data-name="firstName" placeholder="First Name" id="firstName" required="" onChange={handleChange} value={formValues.firstName} onBlur={validateField} /></div>
                            <div><input type="text" className={formErrors?.lastName ? "fieldInvalid input contact-form w-input" : "input contact-form w-input"} maxLength="256" name="lastName" data-name="lastName" placeholder="Last Name" id="lastName" required="" onChange={handleChange} value={formValues.lastName} onBlur={validateField} /></div>

                            <div className={formErrors?.name ? "fieldInvalid" : ""}><input type="tel" className={formErrors?.phoneNumber ? "fieldInvalid input contact-form w-input" : "input contact-form w-input"} maxLength="256" name="phoneNumber" data-name="Mobile" placeholder="Mobile" id="phoneNumber" required="" onChange={handleChange} value={formValues.phoneNumber} onBlur={validateField} /></div>
                            <div><input type="email" className={formErrors?.email ? "fieldInvalid input contact-form w-input" : "input contact-form w-input"} maxLength="256" name="email" data-name="Email" placeholder="Email adress" id="Email" required="" onChange={handleChange} value={formValues.email} onBlur={validateField} /></div>

                            <div id="w-node-_17aa1a62-fee4-aad1-a9d1-88ef83a15a81-fbed42d0"><textarea id="Message" name="comment" maxLength="5000" data-name="Message" placeholder="Please type your message here..." className={formErrors?.comment ? "fieldInvalid text-area contact-form w-input" : "text-area contact-form w-input"} value={formValues.comment} onChange={handleChange} onBlur={validateField} ></textarea></div>

                            <div id="w-node-_17aa1a62-fee4-aad1-a9d1-88ef83a15a81-fbed42d0"><div className="w-commerce-commercecheckoutbillingaddresstogglewrapper checkbox-field-wrapper"><input type="checkbox" id="billing-address-toggle" data-node-type="commerce-checkout-billing-address-toggle-checkbox" className={formErrors.acceptTerms ? "w-commerce-commercecheckoutbillingaddresstogglecheckbox checkbox small fieldInvalid" : "w-commerce-commercecheckoutbillingaddresstogglecheckbox checkbox small"} checked={formValues.acceptTerms} onChange={(e) => setFormValues({ ...formValues, acceptTerms: !formValues.acceptTerms })} /><label htmlFor='billing-address-toggle' className="w-commerce-commercecheckoutbillingaddresstogglelabel mg-bottom-0">I agree the Term and Conditions of Scaping Boots</label></div></div>


                        </div>
                    </form>

                </ModalBody>
                <ModalFooter>
                    <button data-w-id="0fd32bf0-c4f3-9434-1891-06cc06001554" onClick={handleSubmit} className="btn-primary button-row w-button">Submit<span className="line-rounded-icon link-icon-right"></span></button>
                </ModalFooter>
            </Modal>
        </>
    )
}

export default BookADemo;