import React, { useEffect, useState } from 'react';
import {Link, useNavigate, useSearchParams } from 'react-router-dom';
import Footer from '../components/footer';
import Header from '../components/header';
import { sendUser } from '../services/forms.service';


const DownloadPage = () => {
    const navigate = useNavigate();
    const [queryParams,setQueryParams] = useSearchParams();
    const [redirectUrl,setRedirectUrl] = useState();
    const [timer,setTimer]  = useState(15);
    const [downloadUrl,setDownloadUrl] = useState();



    useEffect(()=>{
        setRedirectUrl(queryParams.get("redirectUrl"));
        console.log(queryParams.get("email"))
        setDownloadUrl(queryParams.get("downloadUrl"));
        sendUser({
            email:queryParams.get("email"),
            filePath:queryParams.get("downloadUrl")
        })
    },[])

    useEffect(()=>{
        const interval = setInterval(()=>{
            if(timer>0){
                setTimer(timer-1)
            }else{
                clearInterval(interval)
                window.location.href = redirectUrl;
            }
        },1000)
        return()=>clearInterval(interval)
    },[timer])

    return (<>
        <Header />
        <div style={{ height: "50vh",flexDirection:"column" }} className="container flex justify-center align-center">
            <div>
                <h2>You will be redirected in {timer}s</h2>
            </div>
            <div>
                <iframe src={downloadUrl} style={{display:"none"}}/>
                <a data-w-id="0fd32bf0-c4f3-9434-1891-06cc06001554" download className="btn-primary button-row w-button">Download Now</a>
                <a data-w-id="64d35d5a-33ef-14d4-cdc3-b90db669e099" href={redirectUrl} className="link-wrapper w-inline-block">
                    <div className="link-text">Redirect Now</div>
                    <div className="line-rounded-icon link-icon-right"></div>
                </a>
            </div>
        </div>
        <Footer />
    </>
    )
}

export default DownloadPage;