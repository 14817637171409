import React, { useState } from 'react'
import Footer from '../components/footer';
import Header from '../components/header';
import "./css/styles.css";
import "./css/styles1.css";
import "./css/styles2.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle } from 'react-bootstrap';
import BookADemo from './bookAdemo';



const HomePage = () => {
    const [show, setShow] = useState(false)


    return (
        <>
            <BookADemo
                show={show}
                onClose={setShow}
            />
            <div className="page-wrapper overflow-hidden">
                <Header />
                <div className="section main-hero wf-section">
                    <div className="container-default w-container">
                    <h1 className="happ-home-heading">Leader in Data Extract <br /> and Data Mining </h1>
                        <div data-w-id="bb9e8456-e6ac-eaa9-1207-89d4b069e457" className="inner-container _574px center headLines">
                            <div className="text-center mg-bottom-64px">
                            <Carousel
                                autoPlay
                                infiniteLoop
                                interval={4000}
                                showIndicators={false}
                                showThumbs={false}
                                showStatus={false}
                                showArrows={false}
                            >
                                <div className='item-1'>
                                    <h1> <span className="color-gradient-1">Structured data</span> </h1>
                                    {/* <h1>Get <span className="color-gradient-1">Structured data</span> when you need.</h1> */}
                                    <p className="mg-bottom-40px">Effortlessly centralize all the data you need so your team can deliver better insights, faster.</p>

                                </div>
                                <div className='item-2'>
                                    <h1><span className="color-gradient-1"> Public web data</span> </h1>
                                    {/* <h1>Get <span className="color-gradient-1"> Public web data</span> when you need.</h1> */}
                                    <p className="mg-bottom-40px">Get the Public web data you need when you need it.</p>

                                </div>
                                <div className='item-3'>
                                    <h1><span className="color-gradient-1">Quality data</span> </h1>
                                    {/* <h1>Get <span className="color-gradient-1">Quality data</span> when you need</h1> */}
                                    <p className="mg-bottom-40px">We are responsible for the performance, quality, and delivery.</p>
                                    </div>
                                </Carousel>
                                <div>
                                    <a data-w-id="0fd32bf0-c4f3-9434-1891-06cc06001554" href="/contact" className="btn-primary button-row w-button">Contact Us<span className="line-rounded-icon link-icon-right"></span></a>
                                    <button style={{ background: "transparent" }} data-w-id="64d35d5a-33ef-14d4-cdc3-b90db669e099" onClick={() => setShow(true)} className="link-wrapper w-inline-block">
                                        <div className="link-text">Book a demo</div>
                                        <div className="line-rounded-icon link-icon-right"></div>
                                    </button>
                                </div>
                            </div>
                        </div>
                        {/* <div data-w-id="d028d4c3-9c56-c786-2013-51aad3931e64" id='videoContainer' className="inner-container _1100px center"> */}
                        {/* <!-- <img src="images/developer-tool-codely-webflow-template.png" loading="eager" sizes="(max-width: 479px) 93vw, (max-width: 767px) 94vw, (max-width: 1439px) 95vw, 1068px" srcSet="images/developer-tool-codely-webflow-template-p-500.png 500w, images/developer-tool-codely-webflow-template-p-800.png 800w, images/developer-tool-codely-webflow-template.png 2136w" alt="Developer Tool - Codely Webflow Template"> --> */}

                        <video className="elementor-video" src="https://zerotimeshop.com/assets/scrapingboots.mp4" loop controls controlsList="nodownload" muted="muted" poster="https://cdn-fgdkp.nitrocdn.com/rIogkmlTApjbjYuISbvGFJApdbIikskP/assets/static/optimized/wp-content/uploads/2022/04/95f834d97e368b260f3a7350b89c7450.2022-04-16-22_16_34-WhatsApp-Video-2022-04-16-at-15.14.49.mp4-VLC-media-player.png"></video>


                        {/* </div> */}
                        <div data-w-id="178d398f-f9de-bbcd-5f79-7e6f372d53bd" className="pd---top-bottom-60px brandsContainer">
                            <div className="flex justify-center mg-bottom-48px">
                                <div className="grid-6-columns logo-strip"><img src="images/google-logo-black-codely-webflow-template.svg" loading="eager" id="w-node-e45b410e-8806-85f0-bc5f-5568311363d9-26ed42bc" alt="Google Logo - Codely X Webflow Template" /><img src="images/facebook-logo-black-codely-webflow-template.svg" loading="eager" id="w-node-_89184bee-f488-2c1b-99d6-5db4a9d87104-26ed42bc" alt="Facebook Logo - Codely X Webflow Template" /><img src="images/youtube-logo-black-codely-webflow-template.svg" loading="eager" id="w-node-e80b6f1f-9ee5-2c24-3e51-7cbd279df086-26ed42bc" alt="Youtube Logo - Codely X Webflow Template" /><img src="images/pinterest-logo-black-codely-webflow-template.svg" loading="eager" id="w-node-_864555d8-4b8c-4fd0-2038-d570925fdcdf-26ed42bc" alt="Pinterest Logo - Codely X Webflow Template" /><img src="images/twitch-logo-black-codely-webflow-template.svg" loading="eager" id="w-node-_1c8fa041-ac65-73ad-2355-1ca7d48f3f52-26ed42bc" alt="Twitch Logo - Codely X Webflow Template" /><img src="images/webflow-logo-black-codely-webflow-template.svg" loading="eager" id="w-node-fad9b9c7-e8dd-ce13-a7a5-6ac75aa7a302-26ed42bc" alt="Webflow Logo - Codely X Webflow Template" /></div>
                            </div>
                            <div className="flex justify-center">
                                <div className="grid-5-columns logo-strip"><img src="images/linkedin-logo-black-codely-webflow-template.svg" loading="eager" id="w-node-a2f4857e-abeb-957b-7524-5b4e1abf2353-26ed42bc" alt="Linkedin Logo - Codely X Webflow Template" /><img src="images/discord-logo-black-codely-webflow-template.svg" loading="eager" id="w-node-f3eb80d3-f943-57b8-7c34-01d3e44ae16c-26ed42bc" alt="Discord Logo - Codely X Webflow Template" /><img src="images/medium-logo-black-codely-webflow-template.svg" loading="eager" id="w-node-ae488912-f41c-cefd-5b32-a3172dd604e2-26ed42bc" alt="Medium Logo - Codely X Webflow Template" /><img src="images/reddit-logo-black-codely-webflow-template.svg" loading="eager" id="w-node-a41d2da3-3aa6-2ae3-d55c-ccfa031f62a1-26ed42bc" alt="Reddit Logo - Codely X Webflow Template" /><img src="images/spotify-logo-black-codely-webflow-template.svg" loading="eager" id="w-node-f5145e72-a28d-091a-aaaa-9c7298c1c2f1-26ed42bc" alt="Spotify Logo - Codely X Webflow Template" className="hidden-on-mbl" /></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section bg-neutral-200 wf-section">
                    <div className="container-default w-container">
                        <div data-w-id="a268409a-531f-a4ae-bb97-3da524abaf84" className="inner-container _544px center howItWorksContainer">
                            <div className="text-center mg-bottom-48px">
                                <h2 className="mg-bottom-10px">How it works.</h2>
                                <p>Extract insights with clean, usable, relevant web data.</p>
                            </div>
                        </div>
                        <div data-w-id="a268409a-531f-a4ae-bb97-3da524abaf8a" data-current="HTML/CSS/JS" data-easing="ease" data-duration-in="300" data-duration-out="100" className="tabs-wrapper tabs-left w-tabs howItWorks">
                            <div id="w-node-a268409a-531f-a4ae-bb97-3da524abaf8b-26ed42bc" className="tabs-menu tabs-vertical w-tab-menu">
                                <a data-w-tab="HTML/CSS/JS" data-w-id="a268409a-531f-a4ae-bb97-3da524abaf8c" className="tab-left-icon first w-inline-block w-tab-link w--current"><img loading="eager" src="images/step1.png" alt="HTML5 Logo - Codely X Webflow Template" className="square-icon mg-right-12px" />
                                    <div className="accordion-item-wrapper tab">
                                        <div className="accordion-content-wrapper tab">
                                            <div className="accordion-header">
                                                <div className="accordion-title text-200">Send project details
                                                </div>
                                            </div>
                                            <div className="accordion-icon-wrapper tab">
                                                <div className="line-rounded-icon accordion-icon tab"></div>
                                            </div>
                                        </div>
                                        <div className="acordion-body tab">
                                            <div className="accordion-spacer"></div>
                                            <p className="paragraph-default color-neutral-600 mg-bottom-0">Send us the source websites and the fields for scraping. We will analyze the requirements and ask the right questions to come up with the best solution.</p>
                                        </div>
                                    </div>
                                </a>
                                <a data-w-tab="Python and Django" data-w-id="a268409a-531f-a4ae-bb97-3da524abaf90" className="tab-left-icon w-inline-block w-tab-link"><img src="images/step2.png" alt="Python Logo - Codely X Webflow Template" className="square-icon width-48px mg-right-16px" />
                                    <div className="accordion-item-wrapper tab">
                                        <div className="accordion-content-wrapper tab">
                                            <div className="accordion-header">
                                                <div className="accordion-title text-200">Get free project quote
                                                </div>
                                            </div>
                                            <div className="accordion-icon-wrapper tab">
                                                <div className="line-rounded-icon accordion-icon tab"></div>
                                            </div>
                                        </div>
                                        <div className="acordion-body tab">
                                            <div className="accordion-spacer"></div>
                                            <p className="paragraph-default color-neutral-600 mg-bottom-0">After reviewing and analyzing the requiremenets we will get back to you with a free quote and timeline. Once you approve the quote we will immediately begin the project.</p>
                                        </div>
                                    </div>
                                </a>
                                <a data-w-tab="Ruby on Rails" data-w-id="a268409a-531f-a4ae-bb97-3da524abaf94" className="tab-left-icon w-inline-block w-tab-link"><img src="images/step3.png" alt="Ruby On Rails Logo - Codely X Webflow Template" className="square-icon width-48px mg-right-16px" />
                                    <div className="accordion-item-wrapper tab">

                                        <div className="accordion-content-wrapper tab">
                                            <div className="accordion-header">
                                                <div className="accordion-title text-200">Data delivery
                                                </div>
                                            </div>
                                            <div className="accordion-icon-wrapper tab">
                                                <div className="line-rounded-icon accordion-icon tab"></div>
                                            </div>
                                        </div>
                                        <div className="acordion-body tab">
                                            <div className="accordion-spacer"></div>
                                            <p className="paragraph-default color-neutral-600">We can deliver data in any format csv, xls, json, pdf, sql, txt, etc. Please let us know what format suits you the best.</p>
                                        </div>
                                    </div>
                                </a>

                            </div>
                            <div id="w-node-a268409a-531f-a4ae-bb97-3da524abaf9c-26ed42bc" className="tabs-content w-tab-content">
                                <div data-w-tab="HTML/CSS/JS" className="w-tab-pane w--tab-active"><img sizes="(max-width: 479px) 100vw, (max-width: 767px) 94vw, (max-width: 1439px) 39vw, 481.703125px" srcSet="images/html5-tab-content-codely-webflow-ecommerce-template-p-500.png 500w, images/html5-tab-content-codely-webflow-ecommerce-template-p-800.png 800w, images/html5-tab-content-codely-webflow-ecommerce-template.png 1296w" src="images/html5-tab-content-codely-webflow-ecommerce-template.png" alt="HML5 Interface - Codely X Webflow Template" className="shadow-02 border-radius-24px" /></div>
                                <div data-w-tab="Python and Django" className="w-tab-pane"><img sizes="(max-width: 479px) 100vw, (max-width: 767px) 94vw, (max-width: 1439px) 39vw, 481.703125px" srcSet="images/python-tab-content-codely-webflow-ecommerce-template-p-500.png 500w, images/python-tab-content-codely-webflow-ecommerce-template-p-800.png 800w, images/python-tab-content-codely-webflow-ecommerce-template.png 1296w" src="images/python-tab-content-codely-webflow-ecommerce-template.png" alt="Python Interface - Codely X Webflow Template" className="shadow-02 border-radius-24px" /></div>
                                <div data-w-tab="Ruby on Rails" className="w-tab-pane"><img sizes="(max-width: 479px) 100vw, (max-width: 767px) 94vw, (max-width: 1439px) 39vw, 481.703125px" srcSet="images/ruby-on-rails-tab-content-codely-webflow-ecommerce-template-p-500.png 500w, images/ruby-on-rails-tab-content-codely-webflow-ecommerce-template-p-800.png 800w, images/ruby-on-rails-tab-content-codely-webflow-ecommerce-template.png 1296w" src="images/ruby-on-rails-tab-content-codely-webflow-ecommerce-template.png" alt="Ruby On Rails Interface - Codely X Webflow Template" className="shadow-02 border-radius-24px" /></div>
                                <div data-w-tab="C++ and .NET" className="w-tab-pane"><img sizes="(max-width: 479px) 100vw, (max-width: 767px) 94vw, (max-width: 1439px) 39vw, 481.703125px" srcSet="images/c-tab-content-codely-webflow-ecommerce-template-p-500.png 500w, images/c-tab-content-codely-webflow-ecommerce-template-p-800.png 800w, images/c-tab-content-codely-webflow-ecommerce-template.png 1296w" src="images/c-tab-content-codely-webflow-ecommerce-template.png" alt="C++ Interface - Codely X Webflow Template" className="shadow-02 border-radius-24px" /></div>
                            </div>
                        </div>
                        <div data-w-id="957ab781-3c7a-1fe1-aa95-50498cce9760" className="buttons-row center mg-top-48px getADemoContainer">
                            <a data-w-id="957ab781-3c7a-1fe1-aa95-50498cce9761" href="pricing/real-estate.html" className="btn-primary button-row w-button">Get a demo<span className="line-rounded-icon link-icon-right"></span></a>
                            <button style={{ background: "transparent" }} data-w-id="64d35d5a-33ef-14d4-cdc3-b90db669e099" onClick={() => setShow(true)} className="link-wrapper w-inline-block">
                                <div className="link-text">Book a demo</div>
                                <div className="line-rounded-icon link-icon-right"></div>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="section wf-section">
                    <div className="container-default w-container">
                        <div className="w-layout-grid grid-2-columns text-right-default">
                            <div className="position-relative"><img src="images/codely-interface-2-codely-webflow-ecommerce-template.png" data-w-id="c523138b-3f59-7bcc-c074-2f1e0e569b15" className='scrappingImageContainer' alt="Real Time Refreshing - Codely X Webflow Template" /><img src="images/real-time-refreshing-bg-codely-webflow-ecommerce-template.png" loading="eager" sizes="(max-width: 1439px) 100vw, (max-width: 1919px) 1466.656982421875px, 76vw" srcSet="images/real-time-refreshing-bg-codely-webflow-ecommerce-template-p-500.png 500w, images/real-time-refreshing-bg-codely-webflow-ecommerce-template-p-800.png 800w, images/real-time-refreshing-bg-codely-webflow-ecommerce-template.png 1362w" alt="" className="position-absolute bg-blur-image-left-1" /></div>
                            <div id="w-node-ee46bc2e-3937-ccac-212c-e3df9224f70e-26ed42bc" data-w-id="ee46bc2e-3937-ccac-212c-e3df9224f70e" className="inner-container _480px max-width-tablet-100 scrappingTextContainer">
                                <h2 className="mg-bottom-48px">Scrapingboots crawlers work and refresh data in real time
                                    across the full pipeline</h2>
                                <div>
                                    <div className="flex align-start"><img src="images/collaborators-icon-codely-webflow-ecommerce-template.svg" loading="eager" alt="Collaboration - Codely X Webflow Template" className="mg-right-24px" />
                                        <div>
                                            <h3>Up to 100 sites crawled per collaborator</h3>
                                            <p className="mg-bottom-32px">We have the capability to crawl up to 100 sites per collaborator,
                                                and transform your data discovery daily, in a short amount of time.</p>
                                        </div>
                                    </div>
                                    <div className="flex align-start"><img src="images/works-on-all-platforms-icon-codely-webflow-ecommerce-template.svg" loading="eager" alt="Gear - Codely X Webflow Template" className="mg-right-24px" />
                                        <div>
                                            <h3>Works on Web2, Web3 websites and data sources.</h3>
                                            <p className="mg-bottom-0">Not all sites are created the same, and we therefore have automated the
                                                distribution of crawlers automatically adjusting to the best time.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="buttons-row mg-top-48px">
                                    <a data-w-id="ee46bc2e-3937-ccac-212c-e3df9224f721" href="pricing/real-estate.html" className="btn-primary button-row w-button">Get a demo<span className="line-rounded-icon link-icon-right"></span></a>
                                    <button style={{ background: "transparent" }} data-w-id="64d35d5a-33ef-14d4-cdc3-b90db669e099" onClick={() => setShow(true)} className="link-wrapper w-inline-block">
                                        <div className="link-text">Book a demo</div>
                                        <div className="line-rounded-icon link-icon-right"></div>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="wf-section">
                    <div className="container-default w-container">
                        <div>
                            <div className="inner-section dark bg-top-right">
                                <div data-w-id="34668c2d-20c1-3fa7-30e5-0da2ad0f3262" className="grid-2-columns _1-col-tablet mg-bottom-64px amazingFeature">
                                    <div className="inner-container _428px">
                                        <h2 className="color-neutral-100">Amazing features for to make your work easier</h2>
                                    </div>
                                    <div id="w-node-_34668c2d-20c1-3fa7-30e5-0da2ad0f3266-26ed42bc" className="buttons-row">
                                        <a data-w-id="34668c2d-20c1-3fa7-30e5-0da2ad0f3267" href="pricing/real-estate.html" className="btn-primary white button-row w-button">Get started for free<span className="line-rounded-icon link-icon-right"></span></a>
                                        <button style={{ background: "transparent" }} data-w-id="64d35d5a-33ef-14d4-cdc3-b90db669e099" onClick={() => setShow(true)} className="link-wrapper w-inline-block">
                                            <div className="link-text">Book a demo</div>
                                            <div className="line-rounded-icon link-icon-right"></div>
                                        </button>
                                    </div>
                                </div>
                                <div data-w-id="34668c2d-20c1-3fa7-30e5-0da2ad0f3270" className="w-layout-grid grid-3-columns gap---column-96px---row-102px gap-row-32px-mbl cardLazyLoad">
                                    <div><img src="images/ai-code-autocomplete-icon-codely-webflow-ecommerce-template.svg" alt="AI Code - Codely X Webflow Template" className="mg-bottom-24px" />
                                        <h3 className="color-neutral-100">Ecommerce</h3>
                                        <p className="mg-bottom-0">Gather product details, prices, reviews, images, variations, price monitoring for business intelligence.</p>
                                    </div>
                                    <div><img src="images/over-50-languages-icon-codely-webflow-ecommerce-template.svg" alt="Over 50 Languages - Codely X Webflow Template" className="mg-bottom-24px" />
                                        <h3 className="color-neutral-100">Travel</h3>
                                        <p className="mg-bottom-0">Flights, hotels, restaurants details, prices, availability, reviews, price monitoring.</p>
                                    </div>
                                    <div><img src="images/available-on-all-platforms-icon-codely-webflow-ecommerce-template.svg" alt="Available On All Platforms - Codely X Webflow Template" className="mg-bottom-24px" />
                                        <h3 className="color-neutral-100">Social Media</h3>
                                        <p className="mg-bottom-0">Gather tweets, profiles. posts based on hashtags and keywords from twitter and instagram.</p>
                                    </div>
                                    <div><img src="images/real-time-code-save-icon-codely-webflow-ecommerce-template.svg" alt="Real Time Coding - Codely X Webflow Template" className="mg-bottom-24px" />
                                        <h3 className="color-neutral-100">Real Estate</h3>
                                        <p className="mg-bottom-0">Listings, prices, agent information, price monitoring and much more.</p>
                                    </div>
                                    <div><img src="images/code-debugger-icon-codely-webflow-ecommerce-template.svg" alt="Code Debugger - Codely X Webflow Template" className="mg-bottom-24px" />
                                        <h3 className="color-neutral-100">Recruitment</h3>
                                        <p className="mg-bottom-0">Monitor jobs, companies, company reviews, profiles, resumes.</p>
                                    </div>
                                    <div><img src="images/granular-permissions-icon-codely-webflow-ecommerce-template.svg" alt="Granular Permissions - Codely X Webflow Template" className="mg-bottom-24px" />
                                        <h3 className="color-neutral-100">Brand Monitoring</h3>
                                        <p className="mg-bottom-0">Gather data from brands across social media for competitor analysis.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="pd-top-216px blur-bg-big wf-section">
                    <div className="container-default position-relative w-container">
                        <div data-w-id="b4748ac4-858c-d9d2-291e-e8098adc8203" className='testCrawlerLazyLoad'>
                            <div className="inner-container _604px center">
                                <h2 className="text-center mg-bottom-10px">Test the power of our crawlers, today.</h2>
                            </div>
                            <div className="inner-container _570px center">
                                <p className="text-center mg-bottom-32px">Our crawlers are at the forefront of technology and
                                    work in a breeze to deliver daily fresh new data sets.</p>
                            </div>
                        </div>
                        <div data-w-id="06c24c8d-b99c-c911-f317-4e462b610353" id='getaDemoContainer' className="buttons-row center mg-bottom-64px">
                            <a data-w-id="06c24c8d-b99c-c911-f317-4e462b610354" href="pricing/real-estate.html" className="btn-primary button-row w-button">Get a demo<span className="line-rounded-icon link-icon-right"></span></a>
                            <button style={{ background: "transparent" }} data-w-id="64d35d5a-33ef-14d4-cdc3-b90db669e099" onClick={() => setShow(true)} className="link-wrapper w-inline-block">
                                <div className="link-text">Book a demo</div>
                                <div className="line-rounded-icon link-icon-right"></div>
                            </button>
                        </div>
                        <div data-w-id="3683fd0b-9bf4-0721-1358-75f664d4840b" style={{ opacity: 1 }} className="flex justify-center"><img src="images/codely-interface-3-codely-webflow-ecommerce-template.png" loading="eager" data-w-id="f22a4038-6858-83f1-2d84-f15b1bf79efe" sizes="(max-width: 767px) 77vw, (max-width: 1439px) 78vw, 964.3125px" srcSet="images/codely-interface-3-codely-webflow-ecommerce-template-p-500.png 500w, images/codely-interface-3-codely-webflow-ecommerce-template-p-800.png 800w, images/codely-interface-3-codely-webflow-ecommerce-template.png 1952w" alt="The code editor of the future - Codely X Webflow Template" className="width-82" /><img src="images/cta-big-floating-image-1-codely-webflow-ecommerce-template.svg" loading="eager" id='lazyLoad1' data-w-id="d4a3b070-f12c-ec17-6b3f-f260c13133e2" alt="Programming Language - Codely X Webflow Template" className="floating-item cta-big---item-1" /><img src="images/cta-big-floating-image-2-codely-webflow-ecommerce-template.svg" loading="eager" id='lazyLoad2' data-w-id="1df2613f-3252-e3f4-d3d0-af1f3bdd9756" alt="Developer Tool - Codely X Webflow Template" className="floating-item cta---big-item-2" /></div>
                    </div>
                </div>
                <div className="section pd-310px position-relative wf-section">
                    <div className="container-default w-container">
                        <div className="w-layout-grid grid-2-columns text-left-default">
                            <div data-w-id="2fc4a0a6-a421-e1a7-6d07-22161766e948" className='lazyLoad'>
                                <h2>Data delivered in several
                                    file formats to easily jump into data analysis without conversion required</h2>
                                <p className="mg-bottom-48px">We provide you the data into JSON, XML, WebSocket, Excel, CSV, SQL, TXT, API.</p>
                                <div className="buttons-row">
                                    <a data-w-id="2fc4a0a6-a421-e1a7-6d07-22161766e94e" href="pricing/real-estate.html" className="btn-primary button-row w-button">Get a demo<span className="line-rounded-icon link-icon-right"></span></a>
                                    <button style={{ background: "transparent" }} data-w-id="64d35d5a-33ef-14d4-cdc3-b90db669e099" onClick={() => setShow(true)} className="link-wrapper w-inline-block">
                                        <div className="link-text">Book a demo</div>
                                        <div className="line-rounded-icon link-icon-right"></div>
                                    </button>
                                </div>
                            </div><img src="images/programming-languages-cards-codely-webflow-ecommerce-template.png" sizes="(max-width: 479px) 100vw, (max-width: 767px) 94vw, (max-width: 991px) 95vw, 50vw" data-w-id="2fc4a0a6-a421-e1a7-6d07-22161766e952" srcSet="images/programming-languages-cards-codely-webflow-ecommerce-template-p-500.png 500w, images/programming-languages-cards-codely-webflow-ecommerce-template.png 1552w" alt="The Most Popular Programming Languages - Codely X Webflow Template" className="image-half-right lazyLoad" />
                        </div>
                    </div>
                </div>

                <div className="section bg-neutral-200 wf-section">
                    <div className="container-default w-container">
                        <div data-w-id="186a7c7e-49f8-ea6e-9d03-b926b87dd598"
                            className="grid-2-columns mg-bottom-40px lazyLoad">
                            <h2 className="mg-bottom-0">Data Scraping Company</h2>
                            <a id="w-node-a2326ead-c88b-a106-b4a4-ac7a115ae962-26ed42bc" data-w-id="a2326ead-c88b-a106-b4a4-ac7a115ae962" href="pricing/real-estate.html" className="btn-primary w-button">Get a demo<span className="line-rounded-icon link-icon-right"></span></a>
                        </div>
                        <div className="grid-3-columns _3-col-tablet">
                            <div id="w-node-_9b300f07-c5de-57fa-af3b-8d8f902afb4a-26ed42bc" data-w-id="9b300f07-c5de-57fa-af3b-8d8f902afb4a" className="card content-center max-width-374px lazyLoad"><img src="images/front-end-developers-illustration-codely-webflow-ecommerce-template.svg" loading="eager" id="w-node-d69ffca2-5d0d-2fd3-e818-851abed920b5-26ed42bc" alt="Frontend Managers - Codely X Webflow Template" className="mg-bottom-32px border-radius-24px width-100" />
                                <h3>Web Research</h3>
                                <p className="mg-bottom-0">Our team will help with research to analyze the reliable source of information and provide market data insights for your needs.</p>
                            </div>
                            <div id="w-node-_40a26550-a891-7967-bd0d-0a4764ec2b6a-26ed42bc" data-w-id="40a26550-a891-7967-bd0d-0a4764ec2b6a" className="card content-center max-width-374px lazyLoad"><img src="images/backend-developers-illustration-codely-webflow-ecommerce-template.svg" loading="eager" id="w-node-_40a26550-a891-7967-bd0d-0a4764ec2b6b-26ed42bc" alt="Backend Managers - Codely X Webflow Template" className="mg-bottom-32px border-radius-24px width-100" />
                                <h3>Web Scraping</h3>
                                <p className="mg-bottom-0">Our company has all resources to automate the collection of corresponding public data sets on the web by using custom codes.</p>
                            </div>
                            <div id="w-node-_5553747f-4035-b8a9-fadf-0ae0f5570554-26ed42bc" data-w-id="5553747f-4035-b8a9-fadf-0ae0f5570554" className="card content-center max-width-374px dataAvalysis"><img src="images/devops-illustration-codely-webflow-ecommerce-template.svg" loading="eager" id="w-node-_5553747f-4035-b8a9-fadf-0ae0f5570555-26ed42bc" alt="DevOps - Codely X Webflow Template" className="mg-bottom-32px border-radius-24px width-100" />
                                <h3>Data Analysis</h3>
                                <p className="mg-bottom-0">We will review and analyze the after-effects and work on pattern recognition to establish trends based on your interests.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />
            </div>
        </>
    )
}

export default HomePage;