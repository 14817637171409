import React, { useState } from 'react';
import Footer from '../components/footer';
import Header from '../components/header';
import {
    PayPalScriptProvider,
    PayPalButtons,
    usePayPalScriptReducer
} from "@paypal/react-paypal-js";
import { PAYPAL_CLIENT_ID } from '../constant/constant';



const PaypalCheckout = () => {
    const [succeeded, setSucceeded] = useState(false);
    const [paypalErrorMessage, setPaypalErrorMessage] = useState("");
    const [orderID, setOrderID] = useState(false);
    const [billingDetails, setBillingDetails] = useState("");

    const createOrder = (data, actions) => {
        return actions.order
            .create({
                purchase_units: [
                    {
                        amount: {
                            // charge users $499 per order
                            value: 499,
                        },
                    },
                ],
                // remove the applicaiton_context object if you need your users to add a shipping address
                application_context: {
                    shipping_preference: "NO_SHIPPING",
                },
            })
            .then((orderID) => {
                setOrderID(orderID);
                return orderID;
            });
    };


    const onApprove = (data, actions) => {
        return actions.order.capture().then(function (details) {
            const { payer } = details;
            console.log("details", details)
            setBillingDetails(payer);
            setSucceeded(true);
        })
    };


    const onError = (data, actions) => {
        console.log("error with payment", data)
        setPaypalErrorMessage("Something went wrong with your payment");
    }

    return (
        <div className="page-wrapper overflow-hidden">
            <Header />
            <div className="section small bg-neutral-200 wf-section">
                <div className="container-default w-container">
                    <div data-node-type="commerce-paypal-checkout-form-container" data-wf-checkout-query="" data-wf-page-link-href-prefix="" className="w-commerce-commercepaypalcheckoutformcontainer checkout-form">
                        <div data-w-id="e70b6869-5bf3-1a7c-69d6-aaf2fca0796a" style={{ opacity: 1 }} className="inner-container _600px mg-bottom-40px">
                            <h1 className="heading-h2-size">Checkout with PayPal</h1>
                            <p>Please review your checkout details below. If everything is correct, place your order and you will receive more information via email.</p>
                        </div>
                        <div className="w-layout-grid grid-2-columns checkout-page">
                            <div data-w-id="61a06c974a45ef7f54766f7000000000000c" style={{ opacity: 1 }} className="w-commerce-commercelayoutmain checkout-col-left">

                                <div className="w-commerce-commercecheckoutcustomerinfosummarywrapper card checkout-block">
                                    <div className="w-commerce-commercecheckoutsummaryblockheader checkout-block-header">
                                        <h4>Customer Information</h4>
                                    </div>
                                    <fieldset className="w-commerce-commercecheckoutblockcontent checkout-block-content">
                                        <div className="w-commerce-commercecheckoutrow">
                                            <div className="w-commerce-commercecheckoutcolumn checkout-column">
                                                <div className="w-commerce-commercecheckoutsummaryitem"><label className="w-commerce-commercecheckoutsummarylabel">Email</label>
                                                    <div></div>
                                                </div>
                                            </div>
                                            <div className="w-commerce-commercecheckoutcolumn">
                                                <div className="w-commerce-commercecheckoutsummaryitem"><label className="w-commerce-commercecheckoutsummarylabel">Shipping Address</label>
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                    <div className="w-commerce-commercecheckoutsummaryflexboxdiv">
                                                        <div className="w-commerce-commercecheckoutsummarytextspacingondiv"></div>
                                                        <div className="w-commerce-commercecheckoutsummarytextspacingondiv"></div>
                                                        <div className="w-commerce-commercecheckoutsummarytextspacingondiv"></div>
                                                    </div>
                                                    <div></div>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                </div>
                                <div className="w-commerce-commercecheckoutpaymentsummarywrapper card checkout-block">
                                    <div className="w-commerce-commercecheckoutsummaryblockheader checkout-block-header">
                                        <h4>Payment Info</h4>
                                    </div>
                                    <fieldset className="w-commerce-commercecheckoutblockcontent checkout-block-content">
                                        <div className="w-commerce-commercecheckoutrow">
                                            <div className="w-commerce-commercecheckoutcolumn checkout-column">
                                                <div className="w-commerce-commercecheckoutsummaryitem"><label className="w-commerce-commercecheckoutsummarylabel">Payment Info</label>
                                                    <div className="w-commerce-commercecheckoutsummaryflexboxdiv">
                                                        <div className="w-commerce-commercecheckoutsummarytextspacingondiv"></div>
                                                        <div className="w-commerce-commercecheckoutsummarytextspacingondiv"></div>
                                                    </div>
                                                    <div className="w-commerce-commercecheckoutsummaryflexboxdiv">
                                                        <div></div>
                                                        <div> / </div>
                                                        <div></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="w-commerce-commercecheckoutcolumn">
                                                <div className="w-commerce-commercecheckoutsummaryitem"><label className="w-commerce-commercecheckoutsummarylabel">Billing Address</label>
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                    <div className="w-commerce-commercecheckoutsummaryflexboxdiv">
                                                        <div className="w-commerce-commercecheckoutsummarytextspacingondiv"></div>
                                                        <div className="w-commerce-commercecheckoutsummarytextspacingondiv"></div>
                                                        <div className="w-commerce-commercecheckoutsummarytextspacingondiv"></div>
                                                    </div>
                                                    <div></div>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                </div>
                                <div className="w-commerce-commercecheckoutorderitemswrapper card checkout-block last">
                                    <div className="w-commerce-commercecheckoutsummaryblockheader checkout-block-header">
                                        <h4>Items in Order</h4>
                                    </div>
                                    <fieldset className="w-commerce-commercecheckoutblockcontent checkout-block-content">
                                        <script type="text/x-wf-template" id="wf-template-61a06c974a45ef7f54766f70000000000056"></script>
                                        <div className="w-commerce-commercecheckoutorderitemslist order-item-list" data-wf-collection="database.commerceOrder.userItems" data-wf-template-id="wf-template-61a06c974a45ef7f54766f70000000000056"></div>
                                    </fieldset>
                                </div>
                            </div>
                            <div data-w-id="61a06c974a45ef7f54766f70000000000066" className="w-commerce-commercelayoutsidebar checkout-col-right style1">
                                <div className="w-commerce-commercecheckoutordersummarywrapper card checkout-block">
                                    <div className="w-commerce-commercecheckoutsummaryblockheader checkout-block-header">
                                        <h4>Order Summary</h4>
                                    </div>
                                    <fieldset className="w-commerce-commercecheckoutblockcontent checkout-block-content">
                                        <div className="w-commerce-commercecheckoutsummarylineitem">
                                            <div>Subtotal</div>
                                            <div></div>
                                        </div>
                                        <script type="text/x-wf-template" id="wf-template-61a06c974a45ef7f54766f70000000000070">%3Cdiv%20class%3D%22w-commerce-commercecheckoutordersummaryextraitemslistitem%22%3E%3Cdiv%3E%3C%2Fdiv%3E%3Cdiv%3E%3C%2Fdiv%3E%3C%2Fdiv%3E</script>
                                        <div className="w-commerce-commercecheckoutordersummaryextraitemslist" data-wf-collection="database.commerceOrder.extraItems" data-wf-template-id="wf-template-61a06c974a45ef7f54766f70000000000070">
                                            <div className="w-commerce-commercecheckoutordersummaryextraitemslistitem">
                                                <div></div>
                                                <div></div>
                                            </div>
                                        </div>
                                        <div className="w-commerce-commercecheckoutsummarylineitem">
                                            <div>Total</div>
                                            <div className="w-commerce-commercecheckoutsummarytotal order-summary-price"></div>
                                        </div>
                                    </fieldset>
                                </div>
                                <PayPalScriptProvider options={{"client-id":PAYPAL_CLIENT_ID.clientId,intent:"capture"}}>
                                    <PayPalButtons
                                        style={{
                                            color: "black",
                                            shape: "pill",
                                            label: "pay",
                                            tagline: false,
                                            layout: "vertical",
                                        }}
                                        createOrder={createOrder}
                                        onApprove={onApprove}
                                    />
                                </PayPalScriptProvider>
                                {/* <a href="#" value="Place Order" data-node-type="commerce-checkout-place-order-button" className="w-commerce-commercecheckoutplaceorderbutton btn-primary" data-loading-text="Placing Order...">Place Order</a> */}
                                <div data-node-type="commerce-checkout-error-state" style={{ display: "none" }} className="w-commerce-commercepaypalcheckouterrorstate">
                                    <div className="w-checkout-error-msg" data-w-info-error="There was an error processing your customer info.  Please try again, or contact us if you continue to have problems." data-w-shipping-error="Sorry. We can’t ship your order to the address provided." data-w-billing-error="Your payment could not be completed with the payment information provided.  Please make sure that your card and billing address information is correct, or try a different payment card, to complete this order.  Contact us if you continue to have problems." data-w-payment-error="There was an error processing your payment.  Please try again, or contact us if you continue to have problems." data-w-pricing-error="The prices of one or more items in your cart have changed. Please refresh this page and try again." data-w-extras-error="A merchant setting has changed that impacts your cart. Please refresh and try again." data-w-product-error="One or more of the products in your cart have been removed. Please refresh the page and try again." data-w-invalid-discount-error="This discount is invalid." data-w-expired-discount-error="This discount is no longer available." data-w-usage-reached-discount-error="This discount is no longer available." data-w-requirements-not-met-error="Your order does not meet the requirements for this discount.">There was an error processing your customer info. Please try again, or contact us if you continue to have problems.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default PaypalCheckout;