import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Logo from "../pages/images/scrpingboots_logo_header.png"
import { subscribe } from '../services/forms.service';
import whatsappIcon from "../pages/images/whatsapp.png"
import skypeIcon from "../pages/images/Skype.webp"
import CookieConsent from "react-cookie-consent";

const Footer = () => {
    const [response, setResponse] = useState("")
    const [email, setEmail] = useState("")

    const handleChange = (e) => {
        setEmail(e.target.value)
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (email) {
            let response = await subscribe({ email: email })
            console.log(response)
            if (response.success) {
                setEmail("")
                setResponse("success")
                setTimeout(() => {
                    setResponse("")
                }, 3000)
            } else {
                setResponse("failed")
                setTimeout(() => {
                    setResponse("")
                }, 3000)
            }
        }
    }
    return (
        <footer className="footer-wrapper"><img src="images/footer-bg-blur-codely-webflow-ecommerce-template.png" loading="eager" alt="" className="position-absolute footer-blur-bg" />
            <div className="container-default w-container">
                <div className="footer-top">
                    <div className="w-layout-grid grid-footer">
                        <div id="w-node-_555684ba-e4a1-9bcd-7e57-7429dc0b6976-dc0b6971" data-w-id="555684ba-e4a1-9bcd-7e57-7429dc0b6976">
                            <a href="index.html" aria-current="page" className="footer-logo-wrapper w-inline-block w--current"><img style={{ height: "50px", borderRadius: "7px" }} src={Logo} loading="eager" alt="Logo - scrapingBoots.com" className="header-logo" /></a>
                            <div className="footer-subscribe-card">
                                <div className="mg-bottom-16px">
                                    <div className="text-300 bold color-neutral-100">Subscribe now</div>
                                </div>
                                <div className="w-form">
                                    <form id="wf-form-Footer-Subscribe" name="wf-form-Footer-Subscribe" data-name="Footer Subscribe" onSubmit={handleSubmit}>
                                        <div className="w-layout-grid grid-subscribe-form-button-down"><input type="email" className="input input-subscribe-footer w-input" maxLength="256" name="email" data-name="Footer Email" placeholder="Enter your email..." id="Footer-Email" required="" value={email} onChange={handleChange} /><input type="submit" value="Subscribe" data-wait="Please wait..." id="w-node-_555684ba-e4a1-9bcd-7e57-7429dc0b6983-dc0b6971" className="btn-primary small white w-button" /></div>
                                    </form>
                                    {
                                        (response === "success") &&
                                        <div className="success-message white-message ">
                                            <div className="flex">
                                                <div className="line-rounded-icon success-message-check"></div>
                                                <div className="color-neutral-800 text-left">Succesfully! Subscribed for latest updates</div>
                                            </div>
                                        </div>
                                    }
                                    {
                                        (response === "failed") &&
                                        <div className="error-message ">
                                            <div>Oops! Something went wrong while subscribing.</div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div data-w-id="555684ba-e4a1-9bcd-7e57-7429dc0b698c" className="footer-column">
                            <div className="text-300 bold footer-title">Important Links</div>
                            <div className="footer-menu-content _2-columns">
                                <ul role="list" className="footer-list-wrapper left">
                                    <li className="footer-list-item">
                                        <Link to={"/"} className="footer-link w--current" >Home</Link>

                                    </li>
                                    <li className="footer-list-item">
                                        <Link to={"/about"} className="footer-link" >About</Link>
                                    </li>
                                    <li className="footer-list-item">
                                        <Link to={"/features"} className="footer-link" >Features</Link>
                                    </li>
                                    <li className="footer-list-item">
                                        <Link to={"/pricing/real-estate"} className="footer-link" >Pricing</Link>
                                    </li>
                                    <li className="footer-list-item">
                                        <Link to={"/contact"} className="footer-link" >Contact</Link>
                                    </li>
                                </ul>

                            </div>
                        </div>

                        <div data-w-id="555684ba-e4a1-9bcd-7e57-7429dc0b698c" className="footer-column">
                            <div className="text-300 bold footer-title">Get In Touch</div>
                            <div className="footer-menu-content _2-columns">
                                <ul role="list" className="footer-list-wrapper left">
                                    <li className="footer-list-item">
                                        <p className="footer-link w--current">251 West, 31st street</p>
                                    </li>
                                    <li className="footer-list-item">
                                        <p className="footer-link w--current">New York, NY 10001</p>
                                    </li>
                                    <li className="footer-list-item">
                                        <a href="mailto://info@scrapingboots.com" className="footer-link w--current">info@scrapingboots.com
                                        </a>
                                    </li>
                                    <li className="footer-list-item">
                                        <div style={{ display: "flex" }}>
                                            <a href="https://api.whatsapp.com/send?phone=12345171182&amp;text=Hi" target="_blank"> <img alt="" className=" lazyloaded" style={{ width: "40px", padding: "0px 10px" }} nitro-lazy-empty="" id="MTI0Mzo5Mw==-1" src={whatsappIcon} /> </a>
                                            <a href="https://join.skype.com/invite/vPVF6fzm9gmw" target="_blank"> <img style={{ borderRadius: "15px", width: "40px", padding: "0px 10px" }} alt="" className=" lazyloaded" nitro-lazy-empty="" id="MTI0OToxMjc=-1" src={skypeIcon} /> </a>
                                        </div>
                                    </li>
                                    <li className="footer-list-item">
                                        <a href="tel://+18668880630" className="footer-link w--current">+1 866 888 0630
                                        </a>
                                    </li>
                                </ul>

                            </div>
                        </div>

                        <div data-w-id="555684ba-e4a1-9bcd-7e57-7429dc0b69c9">
                            <div className="text-300 bold footer-title">Follow us</div>
                            <ul role="list" className="footer-list-wrapper">
                                <li className="footer-list-item">
                                    <a data-w-id="555684ba-e4a1-9bcd-7e57-7429dc0b69ce" href="https://www.facebook.com/scrappingboots" className="social-media-link-wrapper w-inline-block">
                                        <div className="social-icon-square footer-icon">
                                            <div className="social-icon-font"></div>
                                        </div>
                                        <div className="social-media-link-text">Facebook</div>
                                    </a>
                                </li>
                                
                                <li className="footer-list-item">
                                    <a data-w-id="555684ba-e4a1-9bcd-7e57-7429dc0b69e3" href="https://www.linkedin.com/company/scrapingboots
" className="social-media-link-wrapper w-inline-block">
                                        <div className="social-icon-square footer-icon">
                                            <div className="social-icon-font"></div>
                                        </div>
                                        <div className="social-media-link-text">LinkedIn</div>
                                    </a>
                                </li>
                                <li className="footer-list-item">
                                    <div id="google_translate_element"></div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div data-w-id="555684ba-e4a1-9bcd-7e57-7429dc0b69f0" className="footer-bottom">
                    <p className="mg-bottom-0">Copyright © Scraping Boots </p>
                </div>
            </div>
            <CookieConsent
                enableDeclineButton
  location={"bottom"}

                declineButtonText="Decline">
                This website uses cookies to improve the user experience.{" "}
                <span style={{ fontSize: "10px" }}>To learn more about our cookie policy or withdraw from it</span>
                </CookieConsent>
                {/* <CookieConsent
  disableStyles={true}
  location={"bottom"}
  buttonClasses="happ-cookie-accept"
  containerClasses="happ-cookie-container"
  contentClasses="text-capitalize"
>
  This website uses cookies to enhance the user experience.{" "}
  <span style={{ fontSize: "10px" }}>This bit of text is smaller :O</span>
</CookieConsent> */}
        </footer>
    )
}

export default Footer;