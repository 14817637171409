import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../components/footer';
import Header from '../components/header';
import "./css/styles.css";
import "./css/styles1.css";
import "./css/styles2.css";

const FeaturesPage = () => {
    return (
        <>
            <div className="page-wrapper overflow-hidden">
               <Header/>
                <div className="section top position-relative---z-index-1 wf-section"><img
                    src="images/features-bg-blur-codely-webflow-ecommerce-template.svg" loading="eager" alt=""
                    className="floating-item top--248px---left--590px" /><img
                        src="images/features-bg-blur-codely-webflow-ecommerce-template.svg" loading="eager" alt=""
                        className="floating-item right--322px---bottom--242px" />
                    <div className="container-default w-container">
                        <div data-w-id="b9e7b39a-5091-0629-430a-00ed2d554ebf"
                            className="inner-container _650px center lazyLoad">
                            <div className="text-center mg-bottom-48px">
                                <h1>Features</h1>
                                <p className="mg-bottom-0">Access clean, valuable data with web scraping services that drive your business
                                    forward</p>
                            </div>
                        </div>
                        <div data-w-id="e6f588dc-a3df-01f7-6bfc-f00a08fab925"
                            className="grid-3-columns _3-col-tablet lazyLoad">
                            <div id="w-node-e6f588dc-a3df-01f7-6bfc-f00a08fab926-73ed42e1" className="card content-center"><img
                                src="images/ai-code-codely-x-webflow-template.png" loading="eager"
                                id="w-node-e6f588dc-a3df-01f7-6bfc-f00a08fab927-73ed42e1" sizes="100vw"
                                srcSet="images/ai-code-codely-x-webflow-template-p-500.png 500w, images/ai-code-codely-x-webflow-template.png 984w"
                                alt="AI Code - Codely X Webflow Template" className="mg-bottom-32px border-radius-24px" />
                                <h2 className="heading-h3-size mg-bottom-8px">Ecommerce</h2>
                                <p className="mg-bottom-0">Gather product details, prices, reviews, images, variations, price monitoring for business intelligence.</p>
                            </div>
                            <div className="card content-center"><img src="images/50-languages-codely-x-webflow-template.png" loading="eager"
                                id="w-node-e6f588dc-a3df-01f7-6bfc-f00a08fab92d-73ed42e1" sizes="100vw"
                                srcSet="images/50-languages-codely-x-webflow-template-p-500.png 500w, images/50-languages-codely-x-webflow-template.png 984w"
                                alt="Over 50 Languages - Codely X Webflow Template" className="mg-bottom-32px border-radius-24px" />
                                <h2 className="heading-h3-size mg-bottom-8px">Travel</h2>
                                <p className="mg-bottom-0">Flights, hotels, restaurants details, prices, availability, reviews, price monitoring.</p>
                            </div>
                            <div className="card content-center"><img src="images/available-on-all-platforms-codely-x-webflow-template.png"
                                loading="eager" id="w-node-e6f588dc-a3df-01f7-6bfc-f00a08fab933-73ed42e1" sizes="100vw"
                                srcSet="images/available-on-all-platforms-codely-x-webflow-template-p-500.png 500w, images/available-on-all-platforms-codely-x-webflow-template.png 984w"
                                alt="Available On All Platforms - Codely X Webflow Template" className="mg-bottom-32px border-radius-24px" />
                                <h2 className="heading-h3-size mg-bottom-8px">Social Media</h2>
                                <p className="mg-bottom-0">Gather tweets, profiles. posts based on hashtags and keywords from twitter and instagram.</p>
                            </div>
                            <div className="card content-center"><img src="images/real-time-code-save-codely-x-webflow-template.png"
                                loading="eager" id="w-node-_07893b75-0e64-2ae5-78f8-fa0ffed510a0-73ed42e1" sizes="100vw"
                                srcSet="images/real-time-code-save-codely-x-webflow-template-p-500.png 500w, images/real-time-code-save-codely-x-webflow-template.png 984w"
                                alt="Real Time Code Save - Codely X Webflow Template" className="mg-bottom-32px border-radius-24px" />
                                <h2 className="heading-h3-size mg-bottom-8px">Real Estate</h2>
                                <p className="mg-bottom-0">Listings, prices, agent information, price monitoring and much more.</p>
                            </div>
                            <div className="card content-center"><img src="images/code-debugger-codely-x-webflow-template.png" loading="eager"
                                id="w-node-_628e96ad-62e3-b77a-4e19-afc1c14a31ee-73ed42e1" sizes="100vw"
                                srcSet="images/code-debugger-codely-x-webflow-template-p-500.png 500w, images/code-debugger-codely-x-webflow-template.png 984w"
                                alt="Code Debugger - Codely X Webflow Template" className="mg-bottom-32px border-radius-24px" />
                                <h2 className="heading-h3-size mg-bottom-8px">Recruitment</h2>
                                <p className="mg-bottom-0">Monitor jobs, companies, company reviews, profiles, resumes.</p>
                            </div>
                            <div className="card content-center"><img src="images/granular-permissions-codely-x-webflow-template.png"
                                loading="eager" id="w-node-b63a6de6-8268-bf78-4eb7-666e517a5008-73ed42e1" sizes="100vw"
                                srcSet="images/granular-permissions-codely-x-webflow-template-p-500.png 500w, images/granular-permissions-codely-x-webflow-template.png 984w"
                                alt="Granular Permissions - Codely X Webflow Template" className="mg-bottom-32px border-radius-24px" />
                                <h2 className="heading-h3-size mg-bottom-8px">Brand Monitoring</h2>
                                <p className="mg-bottom-0">Gather data from brands across social media for competitor analysis.</p>
                            </div>
                        </div>
                    </div>
                </div>

           
                <Footer/>
            </div>
        </>
    )
}

export default FeaturesPage;