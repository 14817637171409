import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../components/footer';
import Header from '../components/header';
import BookADemo from './bookAdemo';
import "./css/styles.css";
import "./css/styles1.css";
import "./css/styles2.css";
import playIcon from "../pages/images/video-play-button-codely-webflow-ecommerce-template.svg"

const AboutPage = () => {
    const [show, setShow] = useState(false)
    const [play, setPlay] = useState(false)
    const [videoHovered, setVideoHovered] = useState(false)
    const playVideo = () => {

    }
    return (

        <div className="page-wrapper overflow-hidden">
            <BookADemo
                show={show}
                onClose={setShow}
            />
            <Header />
            {
                play &&
                <div class="w-lightbox-backdrop" style={{ transition: "opacity 300ms ease 0s", opacity: "1" }}><div class="w-lightbox-container"><div class="w-lightbox-content"><div class="w-lightbox-view" tabindex="0" id="w-lightbox-view" style={{ opacity: "1" }}><div class="w-lightbox-frame"><figure class="w-lightbox-figure"><img class="w-lightbox-img w-lightbox-image" src="data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20width=%22940%22%20height=%22528%22/%3E" /><iframe class="embedly-embed w-lightbox-embed" src="https://zerotimeshop.com/assets/scrapingboots.mp4" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe></figure></div></div><div class="w-lightbox-spinner w-lightbox-hide" role="progressbar" aria-live="polite" aria-hidden="true" aria-busy="false" aria-valuemin="0" aria-valuemax="100" aria-valuenow="100" aria-valuetext="Loaded image"></div><div class="w-lightbox-control w-lightbox-left w-lightbox-inactive" role="button" aria-hidden="true" aria-controls="w-lightbox-view" aria-label="previous image" tabindex="-1"></div><div class="w-lightbox-control w-lightbox-right w-lightbox-inactive" role="button" aria-hidden="true" aria-controls="w-lightbox-view" aria-label="next image" tabindex="-1"></div><div onClick={() => setPlay(false)} class="w-lightbox-control w-lightbox-close" role="button" aria-label="close lightbox" tabindex="0"></div></div><div class="w-lightbox-strip" role="tablist"></div></div></div>
            }
            <div className="section pd---top-130px-bottom-210px wf-section">
                <div className="container-default w-container">
                    <div className="w-layout-grid grid-2-columns hero-v1">
                        <div data-w-id="67a24f9d-d881-1e0a-a003-2ae02582fe15" className='lazyLoad'>
                            <h1><span className="color-gradient-1">ScrapingBoots </span></h1>
                            <p className="mg-bottom-48px">Access clean, valuable data with web scraping services that drive your business
                                forward</p>
                            <div className="buttons-row mg-top-48px">
                                <a data-w-id="40be1697-6275-7cbf-1e82-651bc63aec1b" href="careers.html"
                                    className="btn-primary button-row w-button">Get data now<span
                                        className="line-rounded-icon link-icon-right"></span></a>
                                <button style={{ background: "transparent" }} data-w-id="64d35d5a-33ef-14d4-cdc3-b90db669e099" onClick={() => setShow(true)} className="link-wrapper w-inline-block">
                                    <div className="link-text">Book a demo</div>
                                    <div className="line-rounded-icon link-icon-right"></div>
                                </button>
                            </div>
                        </div>
                        <div data-w-id="67a24f9d-d881-1e0a-a003-2ae02582fe1f"
                            className="position-relative lazyLoad">
                            <div className="border-radius-24px overflow-hidden">
                                <div className="position-relative z-index---1 "><img src="../images/thumbnail.png"
                                    srcSet="images/thumbnail.png 1080w, images/thumbnail.png 1272w"
                                    sizes="100vw" alt="Start Here - Codely X Webflow Template" className={videoHovered ? "video-bg-image lazyLoad thumbnailHovered" : "video-bg-image lazyLoad"} />
                                    <div className="bg-overlay">
                                        <a href="#" data-w-id="199bf400-b449-4d03-1eeb-668ca8886c65"
                                            className="lightbox-video-icon-wrapper w-inline-block w-lightbox"><img
                                                src={playIcon}
                                                data-w-id="199bf400-b449-4d03-1eeb-668ca8886c66" style={{ opacity: 1 }}
                                                alt="Play Button - Codely X Webflow Template" className="play-button-large"  onClick={() => setPlay(true)} onMouseEnter={() => setVideoHovered(true)} onMouseLeave={() => setVideoHovered(false)} />

                                        </a>
                                    </div>
                                </div>
                            </div><img src="../images/video-bg-blur-codely-webflow-ecommerce-template.svg" loading="eager" alt=""
                                className="position-absolute video-bg-blur" />
                        </div>

                    </div>
                </div>
            </div>
            <div className="section small bg-neutral-200 wf-section">
                <div className="container-default w-container">
                    <div className="w-layout-grid grid-3-columns stats">
                        <div id="w-node-a9103c72-4c41-dd36-7c41-6a8052428452-28ed42c7"
                            data-w-id="a9103c72-4c41-dd36-7c41-6a8052428452"
                            className="text-center mobile-l lazyLoad">
                            <div className="display-2 mg-bottom-24px">99<span className="color-accent-1">%</span></div>
                            <div className="heading-h3-size mg-bottom-8px">Customer satisfaction</div>
                            <p className="mg-bottom-0"> in 12 of 20 parameters, including data accuracy, billing accuracy, responsiveness, handling of admission orders, and timeliness of deliveries.</p>
                        </div>
                        <div data-w-id="a9103c72-4c41-dd36-7c41-6a805242845b"
                            className="text-center mobile-l lazyLoad">
                            <div className="display-2 mg-bottom-24px">1000<span className="color-accent-1">+</span></div>
                            <div className="heading-h3-size mg-bottom-8px">Active users</div>
                            <p className="mg-bottom-0">Our subscribers have expanded their market and refined their pricing strategies</p>
                        </div>
                        <div data-w-id="a9103c72-4c41-dd36-7c41-6a8052428464"
                            className="text-center mobile-l lazyLoad">
                            <div className="display-2 mg-bottom-24px">240<span className="color-accent-1">%</span></div>
                            <div className="heading-h3-size mg-bottom-8px">Company growth</div>
                            <p className="mg-bottom-0">We have grown at a phenomenal pace over the past 2 years and outperform all the market statistics.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div id="learn-more" className="section wf-section">
                <div className="container-default w-container">
                    <div className="inner-container _814px center">
                        <div data-w-id="5233ea16-0b38-0dbf-964c-9b6719648e7e"
                            className="grid-2-columns gap-48px colums-auto top lazyLoad">
                            <div className="width-194px"><img src="../images/story-codely-codely-webflow-template.png" loading="eager"
                                width="242.5" id="w-node-_5233ea16-0b38-0dbf-964c-9b6719648e7f-28ed42c7"
                                alt="Our Story - Codely X Webflow Template" className="mg-top-16px mg-top-0-mbl" /></div>
                            <div>
                                <h2 className="mg-bottom-10px">Our story</h2>
                                <p className="mg-bottom-80px">Founded in 2020, ScrapingBoots is the world's biggest supplier of online and
                                    data crawling services, having offices in both India and the United States. Scraping Intelligence has
                                    operations in both countries. Scraping Intelligence has developed into a world-class web scraping and
                                    data crawling firm in its more than a decade of existence. Through a worldwide network of partners, the
                                    company provides clients with effective and efficient services, tools, and software from all over the
                                    world, including the United States. We think that transparent and honest business methods are essential,
                                    as is the necessity of developing long-term relationships with our customers and business partners. Over
                                    the past 3 years we completed projects for more than 5000 different clients from all over the United
                                    States</p>
                            </div>
                        </div>
                        <div data-w-id="cbc84818-a4c3-a397-f659-209dbb339ed2"
                            className="grid-2-columns gap-48px colums-auto top lazyLoad">
                            <div className="width-194px"><img src="../images/why-we-created-codely-codely-webflow-template.png" loading="eager"
                                width="242.5" id="w-node-cbc84818-a4c3-a397-f659-209dbb339ed3-28ed42c7"
                                alt="Why Us - Codely X Webflow Template" className="mg-top-16px mg-top-0-mbl" /></div>
                            <div>
                                <h2 className="mg-bottom-10px">Why we created Scrapingboots?</h2>
                                <p className="mg-bottom-0">Scrapingboots.com have helped thousands of clients expand their business by
                                    providing them with data mining and data scraping services. We start with an NDA and a Non compete
                                    agreement. Provide us with a list of websites you would like to mine and we will dig for you all the
                                    public details that you cannot see! From Agriculture to Zoology we have dug the secrets of your
                                    competitors! Our data mining technology is sheer power! Your industry intelligence is into the data and
                                    we have it! Contact Scrapingboots.com today and let us know how we can help you leap forward and
                                    ahead! Scrapibgboots.com, your ally for your success!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="position-relative wf-section">
                <div className="container-default w-container">
                    <div className="inner-section medium dark-bg">
                        <h2 data-w-id="c27e12ec-db86-c800-1e96-e116fb648a33"
                            className="text-center color-neutral-100 mg-bottom-72px lazyLoad">We deliver the data from following</h2>
                        <div data-w-id="31007efb-b88e-0443-e74b-01749b4a4617"
                            className="logo-row-grid top mg-bottom-40px lazyLoad"><img
                                src="../images/google-logo-white-codely-webflow-ecommerce-template.svg" loading="eager"
                                alt="Google - Codely X Webflow Template" /><img
                                src="../images/facebook-logo-white-codely-webflow-ecommerce-template.svg" loading="eager"
                                alt="Facebook - Codely X Webflow Template" /><img
                                src="../images/youtube-logo-white-codely-webflow-ecommerce-template.svg" loading="eager"
                                alt="Youtube - Codely X Webflow Template" /><img
                                src="../images/pinterest-logo-white-codely-webflow-ecommerce-template.svg" loading="eager"
                                alt="Pinterest - Codely X Webflow Template" /><img
                                src="../images/twitch-logo-white-codely-webflow-ecommerce-template.svg" loading="eager"
                                id="w-node-_376c8543-5874-116f-32cb-e01bab08d488-28ed42c7" alt="Twitch - Codely X Webflow Template" /><img
                                src="../images/webflow-logo-white-codely-webflow-ecommerce-template.svg" loading="eager"
                                alt="Webflow - Codely X Webflow Template" /></div>
                        <div data-w-id="399d1a77-1934-d67f-b506-b485d0047cae"
                            className="logo-row-grid bottom lazyLoad"><img src="../images/linkedin-logo-white-codely-webflow-ecommerce-template.svg"
                                loading="eager" alt="Linkedin - Codely X Webflow Template" /><img
                                src="../images/discord-logo-white-codely-webflow-ecommerce-template.svg" loading="eager"
                                alt="Discord - Codely X Webflow Template" /><img
                                src="../images/medium-logo-white-codely-webflow-ecommerce-template.svg" loading="eager"
                                alt="Medium - Codely X Webflow Template" className="hidden-on-mbp" /><img
                                src="../images/reddit-logo-white-codely-webflow-ecommerce-template.svg" loading="eager"
                                alt="Reddit - Codely X Webflow Template" className="hidden-on-mbl" /><img
                                src="../images/spotify-logo-white-codely-webflow-ecommerce-template.svg" loading="eager"
                                alt="Spotify - Codely X Webflow Template" className="hidden-on-mbl" /></div>
                    </div>
                </div>
                <div className="bg-neutral-200 bottom-52"></div>
            </div>
            <div className="section wf-section">
                <div className="container-default w-container">
                    <div data-w-id="0fe9dc98-a92d-c11e-a17d-83b4463611f8"
                        className="grid-2-columns lazyLoad">
                        <div className="inner-container _350px">
                            <h2 className="mg-bottom-0">Amazing features</h2>
                            <p className="mg-bottom-0">Amazing features for to make your work easier</p>
                        </div>
                        <a id="w-node-_1990168f-9f35-6e8c-89af-fc0e1a54b811-28ed42c7" data-w-id="1990168f-9f35-6e8c-89af-fc0e1a54b811"
                            href="careers.html" className="btn-primary w-button">Get data now<span
                                className="line-rounded-icon link-icon-right"></span></a>
                    </div>
                    <div data-w-id="9d86b47e-19b4-0a15-c0db-24fcaf8d458c"
                        className="divider lazyLoad"></div>
                    <div data-w-id="92d1fc1e-b3a0-0aa2-150a-54c951c4dfc2"
                        className="grid-3-columns gap-56px lazyLoad">
                        <div className="flex align-start"><img src="../images/innovation-icon-codely-webflow-ecommerce-template.svg"
                            loading="eager" alt="Innovation - Codely X Webflow Template" className="mg-right-24px" />
                            <div>
                                <h3>Ecommerce</h3>
                                <p className="mg-bottom-0">Gather product details, prices, reviews, images, variations, price monitoring for
                                    business intelligence.</p>
                            </div>
                        </div>
                        <div className="flex align-start"><img src="../images/accountability-icon-codely-webflow-ecommerce-template.svg"
                            loading="eager" alt="Accountability - Codely X Webflow Template" className="mg-right-24px" />
                            <div>
                                <h3>Travel</h3>
                                <p className="mg-bottom-0">Flights, hotels, restaurants details, prices, availability, reviews, price
                                    monitoring.</p>
                            </div>
                        </div>
                        <div className="flex align-start"><img src="../images/commitment-icon-codely-webflow-ecommerce-template.svg"
                            loading="eager" alt="Commitment Icon - Codely X Webflow Template" className="mg-right-24px" />
                            <div>
                                <h3>Social Media</h3>
                                <p className="mg-bottom-0">Gather tweets, profiles. posts based on hashtags and keywords from twitter and
                                    instagram.</p>
                            </div>
                        </div>
                        <div className="flex align-start"><img src="../images/teamwork-icon-codely-webflow-ecommerce-template.svg"
                            loading="eager" alt="Teamwork - Codely X Webflow Template" className="mg-right-24px" />
                            <div>
                                <h3>Real Estate</h3>
                                <p className="mg-bottom-0">Listings, prices, agent information, price monitoring and much more.</p>
                            </div>
                        </div>
                        <div className="flex align-start"><img src="../images/ownership-icon-codely-webflow-ecommerce-template.svg"
                            loading="eager" alt="Ownership - Codely X Webflow Template" className="mg-right-24px" />
                            <div>
                                <h3>Recruitment</h3>
                                <p className="mg-bottom-0">Monitor jobs, companies, company reviews, profiles, resumes.</p>
                            </div>
                        </div>
                        <div className="flex align-start"><img src="../images/efficiency-icon-codely-webflow-ecommerce-template.svg"
                            loading="eager" alt="Efficent - Codely X Webflow Template" className="mg-right-24px" />
                            <div>
                                <h3>Brand Monitoring</h3>
                                <p className="mg-bottom-0">Gather data from brands across social media for competitor analysis.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- <div className="section cta wf-section">
          <div className="container-default w-container">
            <div className="w-layout-grid grid-2-columns cta">
              <div data-w-id="809f5a50-e6b3-dbb7-fabe-a8a63dbb3508" className="inner-container _464px max-width-tablet-100">
                <h2>Try the code editor of the future, today.</h2>
                <p className="mg-bottom-32px">Lorem ipsum dolor sit amet consectetur adipiscing elit semper dalar elementum tempus hac tellus.</p>
                <div className="buttons-row">
                  <a data-w-id="809f5a50-e6b3-dbb7-fabe-a8a63dbb350e" href="pricing/real-estate.html" className="btn-primary button-row w-button">Get data now<span className="line-rounded-icon link-icon-right"></span></a>
                  <a data-w-id="809f5a50-e6b3-dbb7-fabe-a8a63dbb3512" href="contact.html" className="link-wrapper w-inline-block">
                    <div className="link-text">Book a demo</div>
                    <div className="line-rounded-icon link-icon-right"></div>
                  </a>
                </div>
              </div>
              <div data-w-id="809f5a50-e6b3-dbb7-fabe-a8a63dbb3517" className="position-relative cta"><img src="../images/cta-floating-image-1-codely-webflow-ecommerce-template.svg" loading="eager" data-w-id="809f5a50-e6b3-dbb7-fabe-a8a63dbb3518" alt="" className="floating-item cta---item-top"/><img src="../images/cta-floating-image-2-codely-webflow-ecommerce-template.svg" loading="eager" alt=" - Codely X Webflow Template" className="floating-item cta---item-bottom"/><img src="../images/codely-interface-4-codely-webflow-ecommerce-template.svg" loading="eager" id="w-node-_809f5a50-e6b3-dbb7-fabe-a8a63dbb351a-3dbb3505" alt="Try the code editor of the future - Codely X Webflow Template" className="width-100"/></div>
            </div>
          </div>
        </div> --> */}


            <Footer />

        </div>
    )
}


export default AboutPage;