import { BASE_API_URL } from "../constant/constant"


export const requestDemo = async (body) => {
    let response = await fetch(BASE_API_URL+"request-demo",{
        method:"POST",
        body:JSON.stringify(body),
        headers:{
            "Content-Type":"application/json"
        }
    })
    let res = await response.json()
    return await res.success;
}

export const subscribe = async (body) => {
    let response =  await fetch(BASE_API_URL+"subscribe",{
        method:"POST",
        headers:{
            "Content-type":"application/json"
        },
        body:JSON.stringify(body)
    })
    let res = await response.json()
    return res;
}

export const submitContactForm = async (body) => {
    let response = await fetch(BASE_API_URL+"contact-us",{
        method:"POST",
        headers:{
            "Content-type":"application/json"
        },
        body:JSON.stringify(body)
    })

    let res = await response.json()
    return res;
    
}

export const sendUser = async (body) => {
    let response = await fetch(BASE_API_URL+"log",{
        method:"POST",
        headers:{
            "Content-type":"application/json"
        },
        body:JSON.stringify(body)
    })

    let res = await response.json()
    console.log("Response",res)
    return res;
}