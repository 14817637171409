import React, { useEffect, useRef, useState } from 'react';
import Footer from '../components/footer';
import Header from '../components/header';
import "./css/styles.css";
import "./css/styles1.css";
import "./css/styles2.css";
import checkIcon from "../pages/images/checkmark-codely-webflow-ecommerce-template.svg"
import planIcon from "../pages/images/growth-icon-codely-webflow-ecommerce-template_1growth-icon-codely-webflow-ecommerce-template.png"
import { useNavigate, useParams } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal'
import { Button } from 'react-bootstrap';
import ModalDialog from 'react-bootstrap/ModalDialog'
import ModalHeader from 'react-bootstrap/ModalHeader'
import ModalTitle from 'react-bootstrap/ModalTitle'
import ModalBody from 'react-bootstrap/ModalBody'
import ModalFooter from 'react-bootstrap/ModalFooter'
import { PAYPAL_CLIENT_ID } from '../constant/constant';
import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js';
import getSymbolFromCurrency from 'currency-symbol-map';
import { getPlanDetails } from '../services/productDetails.service';



const ProductDetails = () => {
    const navigate = useNavigate();
    const { id, slug } = useParams()
    const [show, setShow] = useState(false);
    const [planDetails, setPlanDetails] = useState()
    const [response, setResponse] = useState();
    const [paypalErrorMessage, setPaypalErrorMessage] = useState("");
    const [orderID, setOrderID] = useState(false);
    const [billingDetails, setBillingDetails] = useState();
    const [planChecked,setPlanChecked] = useState(false);


    const createOrder = (data, actions) => {
        return actions.order
            .create({
                purchase_units: [
                    {
                        amount: {
                            // charge users $499 per order
                            value: planDetails.price,
                            currency: planDetails.currency
                        },
                    },
                ],
                // remove the applicaiton_context object if you need your users to add a shipping address
                application_context: {
                    shipping_preference: "NO_SHIPPING",
                },
            })
            .then((orderID) => {
                setOrderID(orderID);
                return orderID;
            });
    };


    const onApprove = (data, actions) => {
        return actions.order.capture().then(function (details) {
            const { payer } = details;
            console.log("details", details);
            setResponse("success")
            setBillingDetails(details);
        })
    };


    const onError = (data, actions) => {
        console.log("error with payment", data)
        setResponse("failed");
        setPaypalErrorMessage("Something went wrong with your payment");
    }


    const handleSubmit = () => {
        setShow(true);
    }

    const handleClose = () => {
        setShow(false)
    }

    const getDetails = async () => {
        let details = await getPlanDetails(id, slug)
        setPlanDetails(details);
        setPlanChecked(true)
    }

    useEffect(() => {
        getDetails()
    }, [])



    
        return (
            <>
                <Modal
                    show={show}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <ModalHeader closeButton>
                        <ModalTitle>Make Payment</ModalTitle>
                    </ModalHeader>
                    <ModalBody>
                    {
                                (response==="success") &&
                                <div className="success-message">
                                    <div className="line-rounded-icon success-message-check"></div>
                                    <div>Payment Successful! with Order id: {billingDetails.id} and further details will be sent to your email</div>
                                </div>
                            }
                            {
                                (response === "failed") &&
                                <div className="error-message ">
                                    <div>Payment Unsuccessful! Something went wrong in your payment</div>
                                </div>
                            }
                            {
                                !response && 
                                <PayPalScriptProvider options={{ "client-id": PAYPAL_CLIENT_ID.clientId, intent: "capture" }}>
                                    <PayPalButtons
                                        style={{
                                            color: "black",
                                            shape: "pill",
                                            label: "pay",
                                            tagline: false,
                                            layout: "vertical",
                                        }}
                                        createOrder={createOrder}
                                        onApprove={onApprove}
                                        onError={onError}
                                        onCancel= {onError}
                                    />
                                </PayPalScriptProvider>
                            }
                    </ModalBody>
                </Modal>
                <div className="page-wrapper overflow-hidden">
                    <Header />
                    <div className="section top wf-section">
                        <div className="container-default w-container">
                            {
                                planDetails?
                                <div className="w-layout-grid grid-2-columns product-page">
                                <div data-w-id="73ce4dad-78c0-a156-a8a8-126025a86e47" style={{ opacity: 1 }}><img data-w-id="0f0b1ed4-3f71-03a2-6d58-0e4e2eb86b25" loading="eager" src={planIcon} alt="" className="image max-height-40px mg-bottom-24px style1" />
                                    <h1 style={{ fontSize: "54px", marginTop: "0px", marginBottom: "16px" }}>{planDetails.title}</h1>
                                    <div className="inner-container _522px max-width-100-tablet">
                                        <p>{planDetails.desc}</p>
                                    </div>
                                    {
                                        planDetails.offerings.map((offer, index) => {
                                            return (
                                                <div key={index} className="flex align-center mg-bottom-16px"><img src={checkIcon} loading="eager" alt="Check - Codely X Webflow Template" className="mg-right-24px" />
                                                    <div className="text-200 bold color-neutral-800">{offer}</div>
                                                </div>
                                            )
                                        })
                                    }

                                    <div dangerouslySetInnerHTML={{ __html: planDetails.html }} className="rich-text w-richtext">
                                    </div>
                                </div>
                                <div id="w-node-dbf466ca-8f17-e78b-fa11-038c0e2b26a8-d8ed42d9" data-w-id="dbf466ca-8f17-e78b-fa11-038c0e2b26a8" className="card add-to-cart cardFormLazyLoad">
                                    <h2 className="heading-h3-size mg-bottom-8px">Order Now</h2>
                                    <p>{planDetails.orderMessage}</p>
                                    <div className="heading-h2-size mg-bottom-24px">{getSymbolFromCurrency(planDetails.currency) ? getSymbolFromCurrency(planDetails.currency) + planDetails.price : planDetails.currency + planDetails.price}</div>
                                    <div>
                                        <div data-node-type="commerce-add-to-cart-form" className="w-commerce-commerceaddtocartform mg-bottom-0">
                                            {/* <div className="mg-bottom-32px">
                                            <div>
                                                <div className="dropdown-input-wrapper"><select
                                                    id="option-set-547f4937a3af446daed588c1adc84e99"
                                                    data-node-type="commerce-add-to-cart-option-select"
                                                    data-commerce-option-set-id="547f4937a3af446daed588c1adc84e99"
                                                    className="input dropdown w-select " onChange={e => setDuration(e.target.value)} >
                                                    <option selected="" value="">Select duration</option>
                                                    <option value="d22490f41a79aa8f123137a09149535e">Monthly</option>
                                                    <option value="b4d83794d7f00c2fce0da17b0682204e">Yearly</option>
                                                </select></div>
                                            </div>
                                        </div> */}
                                            <div>
                                                <button data-node-type="commerce-add-to-cart-button" data-loading-text="Adding to cart..." aria-busy="false" aria-haspopup="dialog" onClick={handleSubmit} className="w-commerce-commerceaddtocartbutton btn-primary width-100" >Make Payment </button>
                                            </div>
                                        </div>
                                        {/* <div style={{ display: "none" }} className="w-commerce-commerceaddtocartoutofstock">
                                        <div>This product is out of stock.</div>
                                    </div> */}
                                        {/* <div data-node-type="commerce-add-to-cart-error" style={{ display: "none" }} className="w-commerce-commerceaddtocarterror">
                                        <div data-node-type="commerce-add-to-cart-error" data-w-add-to-cart-quantity-error="Product is not available in this quantity." data-w-add-to-cart-general-error="Something went wrong when adding this item to the cart." data-w-add-to-cart-mixed-cart-error="You can’t purchase another product with a subscription." data-w-add-to-cart-buy-now-error="Something went wrong when trying to purchase this item." data-w-add-to-cart-checkout-disabled-error="Checkout is disabled on this site." data-w-add-to-cart-select-all-options-error="Please select an option in each set.">Product is not available in this quantity.</div>
                                    </div> */}
                                    </div>
                                </div>
                            </div>
                            :(planChecked && !planDetails)?
                            <>
                            <h2
                                className="text-center mg-bottom-24px lazyLoad">Something went wrong</h2>
                            </>
                            :
                            <></>
                            }
                        </div>
                    </div>
                    <Footer />
                </div>
            </>
        )
    
}

export default ProductDetails;
