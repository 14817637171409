import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import Footer from '../components/footer';
import Header from '../components/header';
import { submitContactForm } from '../services/forms.service';
import "./css/styles.css";
import "./css/styles1.css";
import "./css/styles2.css";


const ContactPage = () => {
    const initialState = { fullName: "", email: "", comment: "", reason: "" }
    const Regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const [formValues, setFormValues] = useState(initialState)
    const [formErrors, setFormErrors] = useState({})
    const [response, setResponse] = useState("")

    useEffect(() => {
        // setFormErrors(validate(e.target))
    }, [formValues])

    const handleChange = (e) => {
        const { name, value } = e.target
        setFormValues({ ...formValues, [name]: value })
        console.log(formValues)
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        let errors = validate(formValues)
        console.log(validate(formValues), formValues)
        if (_.isEmpty(errors)) {
            setFormErrors(errors)
            let response = await submitContactForm(formValues)
            if (response.success) {
                setResponse("success")
                setFormValues(initialState)
            } else {
                setResponse("failed")
            }
        }else{
            setFormErrors(errors)
        }
    }
    const validate = (values) => {
        let errors = {}
        if (!values.fullName) {
            errors.fullName = true;
        }
        if (!values.email || !Regex.test(values.email)) {
            errors.email = true;
        }
        if (!values.reason) {
            errors.reason = true;
        }
        if (!values.comment) {
            errors.comment = true;
        }
        return errors;
    }

    const validateField = (e) => {
        const { name, value } = e.target;
        if (!value) {
            setFormErrors({ ...formErrors, [name]: true })

        } else {
            if (name === "email") {
                if (!Regex.test(value)) {
                    setFormErrors({ ...formErrors, [name]: true })
                } else {
                    setFormErrors({ ...formErrors, [name]: false })
                }
            } else {
                setFormErrors({ ...formErrors, [name]: false })
            }
        }


    }

    return (
        <div className="page-wrapper overflow-hidden">
            <Header />
            <div className="section top position-relative---z-index-1 wf-section"><img src="images/contact-bg-blur-codely-webflow-ecommerce-template.svg" loading="eager" alt="" className="floating-item contact-form-bg-blur" />
                <div className="container-default w-container">
                    <div className="inner-container _790px center">
                        <div data-w-id="b2eb7c56-79f9-abd2-e771-7f5e115b514a" className="text-center mg-bottom-64px lazyLoad">
                            <div className="subtitle mg-bottom-16px">Contact us</div>
                            <h1 className="mg-bottom-0">Get in touch with us</h1>
                        </div>
                        <div data-w-id="17aa1a62-fee4-aad1-a9d1-88ef83a15a6e" className="card contact-form w-form lazyLoad">
                            {
                                (response === "success") &&
                                <div className="success-message">
                                    <div className="line-rounded-icon success-message-check"></div>
                                    <div>Thank you! Your submission has been received!</div>
                                </div>
                            }
                            {
                                (response === "failed") &&
                                <div className="error-message ">
                                    <div>Oops! Something went wrong while submitting the form.</div>
                                </div>
                            }
                            <form id="wf-form-Contact-Page-Form" name="wf-form-Contact-Page-Form" data-name="Contact Page Form" onSubmit={handleSubmit}>
                                <div className={`w-layout-grid grid-2-columns form `}>
                                    <div className={formErrors?.fullName ? "fieldInvalid" : ""}><input type="text" className={formErrors?.fullName ? "fieldInvalid input contact-form w-input" : "input contact-form w-input"} maxLength="256" name="fullName" data-name="Name" placeholder="Full name" id="Name" required="" onChange={handleChange} value={formValues.fullName} onBlur={validateField} /></div>
                                    <div><input type="email" className={formErrors?.email ? "fieldInvalid input contact-form w-input" : "input contact-form w-input"} maxLength="256" name="email" data-name="Email" placeholder="Email adress" id="Email" required="" onChange={handleChange} value={formValues.email} onBlur={validateField} /></div>

                                    {/* <div><input type="tel" className="input contact-form w-input" maxLength="256" name="Phone" data-name="Phone" placeholder="Phone number" id="Phone" required="" /></div>
                                    <div><input type="text" className="input contact-form w-input" maxLength="256" name="Company" data-name="Company" placeholder="Company" id="Company" required="" /></div> */}
                                    <div id="w-node-_9df137bb-e9a5-a6cf-8cd7-cd2b5efd5567-fbed42d0">
                                        <div className="mg-bottom-32px">
                                            <div className="text-200 medium color-neutral-800">Why are you contacting us?</div>
                                        </div>
                                        <div className="grid-2-columns radio-button-grid"><label className="radio-button-field-wrapper small w-radio">
                                            <div className={formErrors?.reason ? "fieldInvalid w-form-formradioinput w-form-formradioinput--inputType-custom radio-button small w-radio-input" : "w-form-formradioinput w-form-formradioinput--inputType-custom radio-button small w-radio-input"}></div><input type="radio" name="reason" id="Support" value="Support" data-name="Support" style={{ opacity: 0, position: "absolute", zIndex: -1 }} onChange={handleChange}/><img loading="eager" src="images/support-icon-codely-webflow-ecommerce-template.svg" alt="Support Icon - Codely X Webflow Template" className="mg-right-12px" /><span className="text-200 color-neutral-600 mg-bottom-0 w-form-label" htmlFor="Support">Get quote</span>
                                        </label><label className="radio-button-field-wrapper small w-radio">
                                                <div className={formErrors?.reason ? "fieldInvalid w-form-formradioinput w-form-formradioinput--inputType-custom radio-button small w-radio-input" : "w-form-formradioinput w-form-formradioinput--inputType-custom radio-button small w-radio-input"}></div><input type="radio" name="reason" id="Sales-and-Demos" value="Sales and Demos" data-name="Sales and Demos" style={{ opacity: 0, position: "absolute", zIndex: -1 }} onChange={handleChange} /><img loading="eager" src="images/sales-and-demos-icon-codely-webflow-ecommerce-template.svg" alt="Sales Icon - Codely X Webflow Template" className="mg-right-12px" /><span className="text-200 color-neutral-600 mg-bottom-0 w-form-label" htmlFor="Sales-and-Demos">Issue with existing service</span>
                                            </label><label className="radio-button-field-wrapper small w-radio">
                                                <div className={formErrors?.reason ? "fieldInvalid w-form-formradioinput w-form-formradioinput--inputType-custom radio-button small w-radio-input" : "w-form-formradioinput w-form-formradioinput--inputType-custom radio-button small w-radio-input"}></div><input type="radio" name="reason" id="Billing-and-Payments" value="Billing and Payments" data-name="Billing and Payments" style={{ opacity: 0, position: "absolute", zIndex: -1 }} onChange={handleChange} /><img loading="eager" src="images/billing-and-payments-icon-codely-webflow-ecommerce-template.svg" alt="Billing Icon - Codely X Webflow Template" className="mg-right-12px" /><span className="text-200 color-neutral-600 mg-bottom-0 w-form-label" htmlFor="Billing-and-Payments">Billing &amp; Payments</span>
                                            </label><label className="radio-button-field-wrapper small w-radio">
                                                <div className={formErrors?.reason ? "fieldInvalid w-form-formradioinput w-form-formradioinput--inputType-custom radio-button small w-radio-input" : "w-form-formradioinput w-form-formradioinput--inputType-custom radio-button small w-radio-input"}></div><input type="radio" name="reason" id="Careers" value="Careers" data-name="Careers" style={{ opacity: 0, position: "absolute", zIndex: -1 }} onChange={handleChange} /><img loading="eager" src="images/careers-icon-codely-webflow-ecommerce-template.svg" alt="Careers Icon - Codely X Webflow Template" className="mg-right-12px" /><span className="text-200 color-neutral-600 mg-bottom-0 w-form-label" htmlFor="Careers">Careers</span>
                                            </label></div>
                                    </div>
                                    <div id="w-node-_17aa1a62-fee4-aad1-a9d1-88ef83a15a81-fbed42d0"><textarea id="Message" name="comment" maxLength="5000" data-name="Message" placeholder="Please type your message here..." className={formErrors?.comment ? "fieldInvalid text-area contact-form w-input" : "text-area contact-form w-input"} value={formValues.comment} onChange={handleChange} onBlur={validateField} ></textarea></div><input type="submit" value="Send Message" data-wait="Please wait..." className="btn-primary w-button" />
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>
            <div className="section bg-neutral-200 position-relative---z-index-1 wf-section">
                <div className="container-default w-container">
                    <div data-w-id="06f86e83-bbb8-596f-16a1-743953381c4f" className="inner-container _650px center lazyLoad">
                        <div className="text-center mg-bottom-40px">
                            <h2>Frequently Asked Questions</h2>
                            {/* <p className="mg-bottom-0">Lorem ipsum dolor sit amet consectetur adipiscing elit aenean id volutpat imperdiet quis at pellentesque nunc commodo nunc purus pulvinar nisi fusce.</p> */}
                        </div>
                    </div>
                    <div data-w-id="93b1f30c-aa8e-9526-4a77-d341d424ccf2" className="inner-container _820px center lazyLoad">
                        <div data-w-id="c5c66493-3836-2b13-d610-f198565777ed" data-current="Getting started" data-easing="ease" data-duration-in="300" data-duration-out="100" className="tabs-wrapper faqs w-tabs lazyLoad">
                            {/* <div className="tabs-menu faqs w-tab-menu">
                                <a data-w-tab="Getting started" className="badge-secondary-tab w-inline-block w-tab-link w--current">
                                    <div className="text-200 medium">Getting started</div>
                                </a>
                                <a data-w-tab="Pricing and Plans" className="badge-secondary-tab w-inline-block w-tab-link">
                                    <div className="text-200 medium">Pricing &amp; plans</div>
                                </a>
                                <a data-w-tab="Sales questions" className="badge-secondary-tab w-inline-block w-tab-link">
                                    <div className="text-200 medium">Sales questions</div>
                                </a>
                            </div> */}
                            <div className="tabs-content width-100 w-tab-content">
                                <div data-w-tab="Getting started" className="w-tab-pane w--tab-active">
                                    <div className="w-layout-grid grid-1-column gap-row-24px width-100">
                                        <div data-w-id="b6e0500e-209e-1a41-4b8e-b096a7b5ba67" className="accordion-item-wrapper space-between">
                                            <div className="accordion-content-wrapper width-720px">
                                                <div className="accordion-header">
                                                    <h3 className="accordion-title">What does the price depend upon?</h3>
                                                </div>
                                                <div className="acordion-body lazyLoad">
                                                    <div className="accordion-spacer"></div>
                                                    <p className="mg-bottom-0">Structure and complexity of the website, depth of the data, total records to be scraped. Websites that block scraping and have some kind of anti-scraping solution deployed to them usually costs more.</p>
                                                </div>
                                            </div>
                                            <div className="accordion-icon-wrapper tab-vertical">
                                                <div className="line-rounded-icon accordion-icon tab-vertical"></div>
                                            </div>
                                        </div>
                                        <div data-w-id="cce2b2a6-a1cc-1248-cf74-96d30abd546b" className="accordion-item-wrapper space-between">
                                            <div className="accordion-content-wrapper width-720px">
                                                <div className="accordion-header">
                                                    <h3 className="accordion-title">
                                                        In what format will I receive my data?</h3>
                                                </div>
                                                <div className="acordion-body lazyLoad">
                                                    <div className="accordion-spacer"></div>
                                                    <p className="mg-bottom-0">We can deliver data in any format csv, xls, json, pdf, sql, txt, etc. Please let us know what format suits you the best.</p>
                                                </div>
                                            </div>
                                            <div className="accordion-icon-wrapper tab-vertical">
                                                <div className="line-rounded-icon accordion-icon tab-vertical"></div>
                                            </div>
                                        </div>
                                        <div data-w-id="3e871224-97a0-3210-c683-cc235f4fc3d4" className="accordion-item-wrapper space-between">
                                            <div className="accordion-content-wrapper width-720px">
                                                <div className="accordion-header">
                                                    <h3 className="accordion-title">When will I receive my data?</h3>
                                                </div>
                                                <div className="acordion-body lazyLoad">
                                                    <div className="accordion-spacer"></div>
                                                    <p className="mg-bottom-0">Once you submit your project requirements, we will get back to you with the project quote and an estimated timeline.</p>
                                                </div>
                                            </div>
                                            <div className="accordion-icon-wrapper tab-vertical">
                                                <div className="line-rounded-icon accordion-icon tab-vertical"></div>
                                            </div>
                                        </div>
                                        <div data-w-id="954e2beb-589b-5045-83cc-68b9c06b3ad6" className="accordion-item-wrapper space-between">
                                            <div className="accordion-content-wrapper width-720px">
                                                <div className="accordion-header">
                                                    <h3 className="accordion-title">
                                                        Can you scrape data on schedule?</h3>
                                                </div>
                                                <div className="acordion-body lazyLoad">
                                                    <div className="accordion-spacer"></div>
                                                    <p className="mg-bottom-0">Yes absolutely. In addition to one-time scrapes, we also provide data on custom schedules, be it daily, weekly, monthly or quarterly.</p>
                                                </div>
                                            </div>
                                            <div className="accordion-icon-wrapper tab-vertical">
                                                <div className="line-rounded-icon accordion-icon tab-vertical"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div data-w-tab="Pricing and Plans" className="w-tab-pane">
                                    <div className="w-layout-grid grid-1-column gap-row-24px width-100">
                                        <div data-w-id="e43a57f2-b531-e57f-0403-3e78c1efc005" className="accordion-item-wrapper space-between">
                                            <div className="accordion-content-wrapper width-720px">
                                                <div className="accordion-header">
                                                    <h3 className="accordion-title">How can I create a Codely account?</h3>
                                                </div>
                                                <div className="acordion-body lazyLoad">
                                                    <div className="accordion-spacer"></div>
                                                    <p className="mg-bottom-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit id venenatis pretium risus euismod dictum egestas orci netus feugiat ut egestas ut sagittis tincidunt phasellus elit etiam cursus orci in. Id sed montes.</p>
                                                </div>
                                            </div>
                                            <div className="accordion-icon-wrapper tab-vertical">
                                                <div className="line-rounded-icon accordion-icon tab-vertical"></div>
                                            </div>
                                        </div>
                                        <div data-w-id="e43a57f2-b531-e57f-0403-3e78c1efc011" className="accordion-item-wrapper space-between">
                                            <div className="accordion-content-wrapper width-720px">
                                                <div className="accordion-header">
                                                    <h3 className="accordion-title">Is codely currently in beta, or public release?</h3>
                                                </div>
                                                <div className="acordion-body lazyLoad">
                                                    <div className="accordion-spacer"></div>
                                                    <p className="mg-bottom-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit id venenatis pretium risus euismod dictum egestas orci netus feugiat ut egestas ut sagittis tincidunt phasellus elit etiam cursus orci in. Id sed montes.</p>
                                                </div>
                                            </div>
                                            <div className="accordion-icon-wrapper tab-vertical">
                                                <div className="line-rounded-icon accordion-icon tab-vertical"></div>
                                            </div>
                                        </div>
                                        <div data-w-id="e43a57f2-b531-e57f-0403-3e78c1efc01d" className="accordion-item-wrapper space-between">
                                            <div className="accordion-content-wrapper width-720px">
                                                <div className="accordion-header">
                                                    <h3 className="accordion-title">Do you offer any discounts or special pricing for nonprofits?</h3>
                                                </div>
                                                <div className="acordion-body lazyLoad">
                                                    <div className="accordion-spacer"></div>
                                                    <p className="mg-bottom-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit id venenatis pretium risus euismod dictum egestas orci netus feugiat ut egestas ut sagittis tincidunt phasellus elit etiam cursus orci in. Id sed montes.</p>
                                                </div>
                                            </div>
                                            <div className="accordion-icon-wrapper tab-vertical">
                                                <div className="line-rounded-icon accordion-icon tab-vertical"></div>
                                            </div>
                                        </div>
                                        <div data-w-id="e43a57f2-b531-e57f-0403-3e78c1efc029" className="accordion-item-wrapper space-between">
                                            <div className="accordion-content-wrapper width-720px">
                                                <div className="accordion-header">
                                                    <h3 className="accordion-title">Can I invite my team members to my Codely account?</h3>
                                                </div>
                                                <div className="acordion-body lazyLoad">
                                                    <div className="accordion-spacer"></div>
                                                    <p className="mg-bottom-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit id venenatis pretium risus euismod dictum egestas orci netus feugiat ut egestas ut sagittis tincidunt phasellus elit etiam cursus orci in. Id sed montes.</p>
                                                </div>
                                            </div>
                                            <div className="accordion-icon-wrapper tab-vertical">
                                                <div className="line-rounded-icon accordion-icon tab-vertical"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-w-tab="Sales questions" className="w-tab-pane">
                                    <div className="w-layout-grid grid-1-column gap-row-24px width-100">
                                        <div data-w-id="5e4642b8-d4df-e8df-2e2c-c3782f4f18b5" className="accordion-item-wrapper space-between">
                                            <div className="accordion-content-wrapper width-720px">
                                                <div className="accordion-header">
                                                    <h3 className="accordion-title">How can I create a Codely account?</h3>
                                                </div>
                                                <div className="acordion-body lazyLoad">
                                                    <div className="accordion-spacer"></div>
                                                    <p className="mg-bottom-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit id venenatis pretium risus euismod dictum egestas orci netus feugiat ut egestas ut sagittis tincidunt phasellus elit etiam cursus orci in. Id sed montes.</p>
                                                </div>
                                            </div>
                                            <div className="accordion-icon-wrapper tab-vertical">
                                                <div className="line-rounded-icon accordion-icon tab-vertical"></div>
                                            </div>
                                        </div>
                                        <div data-w-id="5e4642b8-d4df-e8df-2e2c-c3782f4f18c1" className="accordion-item-wrapper space-between">
                                            <div className="accordion-content-wrapper width-720px">
                                                <div className="accordion-header">
                                                    <h3 className="accordion-title">Is codely currently in beta, or public release?</h3>
                                                </div>
                                                <div className="acordion-body lazyLoad">
                                                    <div className="accordion-spacer"></div>
                                                    <p className="mg-bottom-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit id venenatis pretium risus euismod dictum egestas orci netus feugiat ut egestas ut sagittis tincidunt phasellus elit etiam cursus orci in. Id sed montes.</p>
                                                </div>
                                            </div>
                                            <div className="accordion-icon-wrapper tab-vertical">
                                                <div className="line-rounded-icon accordion-icon tab-vertical"></div>
                                            </div>
                                        </div>
                                        <div data-w-id="5e4642b8-d4df-e8df-2e2c-c3782f4f18cd" className="accordion-item-wrapper space-between">
                                            <div className="accordion-content-wrapper width-720px">
                                                <div className="accordion-header">
                                                    <h3 className="accordion-title">Do you offer any discounts or special pricing for nonprofits?</h3>
                                                </div>
                                                <div className="acordion-body lazyLoad">
                                                    <div className="accordion-spacer"></div>
                                                    <p className="mg-bottom-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit id venenatis pretium risus euismod dictum egestas orci netus feugiat ut egestas ut sagittis tincidunt phasellus elit etiam cursus orci in. Id sed montes.</p>
                                                </div>
                                            </div>
                                            <div className="accordion-icon-wrapper tab-vertical">
                                                <div className="line-rounded-icon accordion-icon tab-vertical"></div>
                                            </div>
                                        </div>
                                        <div data-w-id="5e4642b8-d4df-e8df-2e2c-c3782f4f18d9" className="accordion-item-wrapper space-between">
                                            <div className="accordion-content-wrapper width-720px">
                                                <div className="accordion-header">
                                                    <h3 className="accordion-title">Can I invite my team members to my Codely account?</h3>
                                                </div>
                                                <div className="acordion-body lazyLoad">
                                                    <div className="accordion-spacer"></div>
                                                    <p className="mg-bottom-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit id venenatis pretium risus euismod dictum egestas orci netus feugiat ut egestas ut sagittis tincidunt phasellus elit etiam cursus orci in. Id sed montes.</p>
                                                </div>
                                            </div>
                                            <div className="accordion-icon-wrapper tab-vertical">
                                                <div className="line-rounded-icon accordion-icon tab-vertical"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <Footer />
        </div>
    )
}

export default ContactPage;