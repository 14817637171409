import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import HomePage from './pages/home';
import FeaturesPage from './pages/features';
import AboutPage from './pages/about';
import RealEstatePricingPage from './pages/realEstate';
import { BrowserRouter,Routes,Route } from "react-router-dom";
import ContactPage from './pages/contact';
import DownloadPage from './pages/downloads';
import ProductDetails from './pages/productDetails';
import PaypalCheckout from './pages/paypalCheckout';

function App() {
  return (
    <BrowserRouter>
    <Routes>
      <Route path='/' element={<HomePage/>} />
      <Route path='/about' element={<AboutPage/>}/>
      <Route path='/features' element={<FeaturesPage/>}/>
      <Route path='/pricing/*' element={<Pricing/>}/>
      <Route path='/contact' element={<ContactPage/>}/>
      <Route path='/downloads' element={<DownloadPage/>}/>
    </Routes>
    </BrowserRouter>
  );
}

export default App;


const Pricing = () => {
  return(
    <Routes>
      <Route path='/:slug' element={<RealEstatePricingPage/>}/>
      <Route path='/:slug/details/:id' element={<ProductDetails/>}/>
      <Route path='/:slug/details/:id/checkout' element={<PaypalCheckout/>} />
    </Routes>
  )
}