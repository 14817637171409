import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Logo from "../pages/images/scrpingboots_logo_header.png"
import "../pages/css/styles.css"
import "../pages/css/styles1.css"
import "../pages/css/styles2.css"
import BookADemo from '../pages/bookAdemo';

const Header = () => {
    const [show,setShow] = useState(false)
    const [open, setOpen] = useState(false)
    const toggleNav = () => {
        if (open) {
            setOpen(false)
        } else {
            setOpen(true)
        }
    }

    return (
        <>
         <BookADemo
            show={show}
            onClose={setShow}
        />
            <div data-w-id="c2ac369a-bb01-2411-3913-ecdf6c5877a2" data-animation="default" data-collapse="medium" data-duration="400" data-easing="ease" data-easing2="ease" role="banner" className="header-wrapper w-nav" style={{ position: "fixed", width: "100%", background: "white", top: "0px", zIndex: 999 }}>
                <div data-w-id="c2ac369a-bb01-2411-3913-ecdf6c5877a3" className="container-default w-container">
                    <div className="header-content-wrapper">
                        <a href="/" aria-current="page" className="header-logo-link w-nav-brand w--current"><img style={{ height: "50px" }} src={Logo} loading="eager" alt="Logo - scrapingBoots.com" className="header-logo" /></a>
                        <div className="header-middle">
                            <nav role="navigation" className="header-nav-menu-wrapper w-nav-menu">
                                <ul role="list" className="header-nav-menu-list">
                                    <li className="header-nav-list-item middle">
                                        <Link to={"/"} aria-current="page" className="header-nav-link w-nav-link w--current">Home</Link>
                                    </li>
                                    <li className="header-nav-list-item middle">
                                        <Link to={"/about"} className="header-nav-link w-nav-link ">About</Link>
                                    </li>
                                    <li className="header-nav-list-item middle">
                                        <Link to={"/features"} className="header-nav-link w-nav-link ">Features</Link>
                                    </li>
                                    <li className="header-nav-list-item middle">
                                        <Link to={"/pricing/real-estate"} className="header-nav-link
                                        w-nav-link">Pricing</Link>
                                    </li>
                                    <li className="header-nav-list-item middle">
                                        <Link to={"/contact"} className="header-nav-link
                                        w-nav-link">Contact</Link>
                                    </li>

                                    <li className="header-nav-list-item show-in-tablet">
                                        <Link to={"/pricing/real-estate"} className="btn-primary small w-button">Book a demo</Link>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                        <div className="header-right-side">

                            <div className="hamburger-menu-wrapper w-nav-button" onClick={toggleNav}>
                                <div className={open ? "hamburger-menu-bar top hamburger1 transformHamBurger1" : "hamburger-menu-bar top hamburger1"} ></div>
                                <div className={open ? "hamburger-menu-bar bottom hamburger2 transformHamBurger2" : "hamburger-menu-bar bottom hamburger2"} ></div>
                            </div>
                            <button onClick={()=>setShow(true)}  className="btn-primary small header-btn-hidde-on-mb w-button">Book a demo</button>
                        </div>
                    </div>

                    <div className={open ? "hamBurger hamBurger-open" : "hamBurger "}>
                        <ul role="list" className="header-nav-menu-list">
                            <li className="header-nav-list-item middle">
                                <Link to={"/"} aria-current="page" className="header-nav-link w-nav-link w--current">Home</Link>
                            </li>
                            <li className="header-nav-list-item middle">
                                <Link to={"/about"} className="header-nav-link w-nav-link ">About</Link>
                            </li>
                            <li className="header-nav-list-item middle">
                                <Link to={"/features"} className="header-nav-link w-nav-link ">Features</Link>
                            </li>
                            <li className="header-nav-list-item middle">
                                <Link to={"/pricing/real-estate"} className="header-nav-link
                                        w-nav-link">Pricing</Link>
                            </li>
                            <li className="header-nav-list-item middle">
                                <Link to={"/contact"} className="header-nav-link
                                        w-nav-link">Contact</Link>
                            </li>

                            <li className="header-nav-list-item show-in-tablet">
                                <Link to={"/pricing/real-estate"} className="btn-primary small w-button">Book a demo</Link>
                            </li>
                        </ul>
                    </div>

                </div>
            </div>

        </>
    )
}

export default Header;