import getSymbolFromCurrency from 'currency-symbol-map';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Footer from '../components/footer';
import Header from '../components/header';
import { getPlans } from '../services/pricing.service';
import BookADemo from './bookAdemo';
import "./css/styles.css";
import "./css/styles1.css";
import "./css/styles2.css";


const RealEstatePricingPage = () => {
    const { slug } = useParams()
    const [comparison, setComparison] = useState([])
    const [plans,setPlans] =  useState([])
    const [planChecked,setPlanChecked] = useState(false);
    const [show,setShow] = useState(false);

    const getAllPlans = async () => {
        let plans = await getPlans(slug)
        setComparison(plans?.data)
        setPlans(plans?.plans)
        setPlanChecked(true)
    }

    useEffect(() => {
        getAllPlans()
    },[])
    return (
        <div className="page-wrapper overflow-hidden">
            <BookADemo
                show={show}
                onClose={setShow}
            />
            <Header />
            <div className="section top position-relative---z-index-1 wf-section">
                <div className="container-default w-container">

                    <div data-w-id="5c3a082f-ec1f-5812-c5e5-2362d59bb6ce"
                        className="w-dyn-list lazyLoad">
                            <div data-w-id="186a7c7e-49f8-ea6e-9d03-b926b87dd598"
                            className="card grid-2-columns mg-bottom-40px lazyLoad" style={{padding:"50px 10px"}}>
                            <h2 style={{textAlign:"center"}} className="mg-bottom-0">Want a Custom Plan?</h2>
                            <div style={{display:"flex",flexWrap:"wrap",justifyContent:"center",alignItems:"center"}}>
                                    <a data-w-id="0fd32bf0-c4f3-9434-1891-06cc06001554" href="/contact" className="btn-primary button-row w-button">Contact Us<span className="line-rounded-icon link-icon-right"></span></a>
                                    <button style={{ background: "transparent",border:"none" }} data-w-id="64d35d5a-33ef-14d4-cdc3-b90db669e099" onClick={() => setShow(true)} className="link-wrapper w-inline-block">
                                        <div className="link-text">Book a demo</div>
                                        <div className="line-rounded-icon link-icon-right"></div>
                                    </button>
                                </div>
                        </div>
                    </div>
                    {
                        (comparison && comparison.length) ?
                            <>
                                <h2
                                    className="text-center mg-bottom-24px lazyLoad">Compare our plans</h2>
                                <div data-w-id="affa991a-5fca-83bc-a8d2-8fef18e28b74" style={{ opacity: 1 }}
                                    className="w-layout-grid grid-4-columns pricing-tablet">
                                    <div className="pricing-column first">
                                        <div className="pricing-table-top first"></div>
                                        {
                                            comparison.map((data, index) => (
                                                <>
                                                    <div key={index} className="pricing-content-wrapper left">
                                                        <div className="text-300 bold color-neutral-800">{data.title}</div>
                                                    </div>
                                                </>
                                            ))
                                        }
                                    </div>
                                   {
                                       plans.map((plan,index)=>(
                                        <div key={index} className={index%2!==0?"pricing-column featured":"pricing-column"}>
                                        <div className={index%2!==0?"pricing-table-top featured":"pricing-table-top"}>
                                            <div className="text-200 medium mg-bottom-8px">{getSymbolFromCurrency(plan.currency)+plan.price}/month</div>
                                            <div className="text-400 bold color-neutral-800">{plan.title}</div>
                                            <Link to={`details/${index}`} className="btn-primary small width-100 mg-top-16px w-button" >Get started</Link>
                                        </div>
                                        {
                                            comparison.map((data,key) => {
                                                if (data.type === "text") {
                                                    return (
                                                        <>
                                                            <div key={key} className="pricing-content-wrapper">
                                                                <div className="pricing-title-tablet">
                                                                    <div className="text-300 bold color-neutral-800">{data.title}</div>
                                                                </div>
                                                                <div className="text-300 medium">{data?.values[index]}</div>
                                                            </div>
                                                        </>
                                                    )
                                                }else if(data.type === "boolean"){
                                                    if(data.values[index]){
                                                        return(
                                                        <>
                                                        <div key={key} className="pricing-content-wrapper empty">
                                                    <div className="pricing-title-tablet">
                                                        <div className="text-300 bold color-neutral-800">{data.title}</div>
                                                    </div><img src="../images/checkmark-codely-webflow-ecommerce-template.svg" loading="eager"
                                                        alt="Check - Codely X Webflow Template" />
                                                </div>
                                                        </>)
                                                    }else{
                                                        return(
                                                            <div key={key} className="pricing-content-wrapper empty"></div>
                                                        )
                                                    }
                                                    }
                                                
                                            })
                                        }

                                    </div>
                                       ))
                                   }
                                    
                                </div>
                            </>
                            :
                            (planChecked && !(comparison && comparison.length))?
                            <h2 className="text-center mg-bottom-24px lazyLoad">Something went wrong</h2>
                            :
                            <></>
                    }
                </div>
            </div>

            <Footer />
        </div>
    )
}

export default RealEstatePricingPage;